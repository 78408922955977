import React, {Fragment, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom'; 
import Moment from 'react-moment';
import { CANDY_MACHINE_ADDRESS, TOKEN_PROGRAM_ID, backend_api_url, checkIfMintTransaction, checkIfPublishTransaction, getConnectionConfig, getCurrentWallet, getGachaMachineInfo, getUsdcMintAddress, getUserSessionData, isTransferNft } from "./constants";
import { useConnection } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL, Connection, clusterApiUrl, Keypair, PublicKey} from "@solana/web3.js";
import * as splToken from "@solana/spl-token";
import { Metaplex, keypairIdentity } from "@metaplex-foundation/js";
import { sign } from "crypto";

const LedgerItemNew = (props: any) => {

    const { connection } = useConnection();
    const [ledgerType, setLedgerType] =  useState<JSX.Element | null>(null)
    const [txnDate, setTxnDate] = useState('');
    const [fromWallet, setFromWallet] = useState<JSX.Element | null>(null)
    const [toWallet, setToWallet] = useState<JSX.Element | null>(null)

    const [amount, setAmount] = useState<JSX.Element | null>(null);
    const [balance, setBalance] = useState('');

    const [transactionType, setTransactionType]   = useState<JSX.Element | null>(null)

    const [isFound, setIsFound] = useState(false);
 
const [fromWalletRevShare, setFromWalletRevShare] = useState<JSX.Element | null>(null)
const [toWalletRevShare, setToWalletRevShare] = useState<JSX.Element | null>(null)
const [ledgerTypeRevShare, setLedgerTypeRevShare] =  useState<JSX.Element | null>(null)
const [amountRevShare, setAmountRevShare] = useState<JSX.Element | null>(null);

const [isSoldToOwn, setIsSoldToOwn] = useState(false);
const [transactionTypeSoldToOwn, setTransactionTypeSoldToOwn]   = useState<JSX.Element | null>(null)
const [amountRevShareSoldToOwn, setAmountRevShareSoldToOwn] = useState<JSX.Element | null>(null);
 
    const getMintAmountForCollectionNew = async (candyMachineAddress, userWallet, cluster) => {
        //setAmount(<Fragment>sample USDC</Fragment>);
        

        const params = {
            address: candyMachineAddress
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/get-info-by-mint-address', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    let amount = data.price;

                    setAmount(<Fragment>$ {amount.toFixed(2)}</Fragment>);
  
                    if(data.ownerWallet === userWallet) {
                        ;//setIsSoldToOwn(true);
                    }
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://explorer.solana.com/address/'+data.address+'?cluster='+ cluster}>{data.address}</a>);
                }else{
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://explorer.solana.com/address/'+candyMachineAddress+'?cluster='+ cluster}>{candyMachineAddress}</a>);

                }


            });
        return;
    }  

    const fetchLedgerInfo = async () => {
        const signature = props.signature;
         
        const config = getConnectionConfig(); 
        let cluster = config.cluster;

        const userData = getUserSessionData();
        if(userData) {
            
            if(userData.wallet) {
                try{

                    
                //const transactionData = await connection.getTransaction(signature);
                // const transactionData = await connection.getTransaction(signature, {
                //     maxSupportedTransactionVersion : 0
                // });
                
                const transactionData = props.transaction;

                //const accountKeys = transactionData.transaction.message.staticAccountKeys;
              
                const fee = transactionData.fee / LAMPORTS_PER_SOL;
                let userWallet = getCurrentWallet();
                

                setLedgerType(<a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://explorer.solana.com/tx/'+signature+'?cluster='+ cluster}>View</a>);

                let isPublish = false;

                // if(accountKeys[0].toBase58() === userWallet && accountKeys.length > 5) {
                //     for(var i in accountKeys) {
                //         if(accountKeys[i].toBase58() === CANDY_MACHINE_ADDRESS) {
                //             isPublish = true;
                //         }
                //     }
                // }
                // if(!isPublish) {
                //     //we check the messages
                //     if(checkIfPublishTransaction(transactionData.meta.logMessages)) {
     
                //         isPublish = true;
                //     }
                // }

                if(transactionData.signature == '2nUvfwzoz2vDYL3aG21ej1Lm3ibrXmLMWHPvcKGa1umYrSWzT1NKdm12aoytcAP5YoMDwQBkKsHt6KuatNbB8v1') {
                    console.log(transactionData);
                }
 
                 
                if(transactionData.type === 'COMPRESSED_NFT_MINT') {
                    if(transactionData.events.compressed[0].oldLeafOwner == null) {
                        setIsFound(false);
                    } else {
                        setToWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://explorer.solana.com/address/'+transactionData.events.compressed[0].newLeafOwner+'?cluster='+ cluster}>{transactionData.events.compressed[0].newLeafOwner}</a>);
                        setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://explorer.solana.com/address/'+transactionData.events.compressed[0].oldLeafOwner+'?cluster='+ cluster}>{transactionData.events.compressed[0].oldLeafOwner}</a>);
                    
                        
                        setBalance ('-');
                        setTransactionType(<Fragment><a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://translator.shyft.to/address/'+transactionData.events.compressed[0].assetId+'?cluster='+ cluster+'&compressed=true'}>Mint NFT</a></Fragment>);
    
                        //await getMintAmountForCollectionNew(accountKeys[0].toBase58(), userWallet);

                        setIsFound(true);
                    }
                } else if(transactionData.type === 'COMPRESSED_NFT_TRANSFER') {
                    setToWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://explorer.solana.com/address/'+transactionData.events.compressed[0].newLeafOwner+'?cluster='+ cluster}>{transactionData.events.compressed[0].newLeafOwner}</a>);
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://explorer.solana.com/address/'+transactionData.events.compressed[0].oldLeafOwner+'?cluster='+ cluster}>{transactionData.events.compressed[0].oldLeafOwner}</a>);
                
                    
                    setBalance ('-');
                    setAmount(<a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://translator.shyft.to/address/'+transactionData.events.compressed[0].assetId+'?cluster='+ cluster+'&compressed=true'}>NFT</a>);

                    if (transactionData.events.compressed[0].instructionIndex === 0) {
                        if(transactionData.events.compressed[0].newLeafOwner === userWallet) {
                            setTransactionType(<Fragment>Receive NFT</Fragment>);

                        } else {
                            setTransactionType(<Fragment>Send NFT</Fragment>);
                        }
                    } else {
                        setTransactionType(<Fragment><a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://translator.shyft.to/address/'+transactionData.events.compressed[0].assetId+'?cluster='+ cluster+'&compressed=true'}>Mint NFT</a></Fragment>);
                        await getMintAmountForCollectionNew(transactionData.events.compressed[0].oldLeafOwner, userWallet, cluster);
                    }
                    //

                    setIsFound(true);
                } else if(transactionData.type === 'NFT_MINT') {
                   
                    setToWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://explorer.solana.com/address/'+transactionData.tokenTransfers[0].toUserAccount+'?cluster='+ cluster}>{transactionData.tokenTransfers[0].toUserAccount}</a>);
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://explorer.solana.com/address/'+transactionData.tokenTransfers[0].toTokenAccount+'?cluster='+ cluster}>{transactionData.tokenTransfers[0].toTokenAccount}</a>);
                
                    
                    setBalance ('-');
                    setTransactionType(<Fragment><a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://translator.shyft.to/address/'+transactionData.tokenTransfers[0].mint+'?cluster='+ cluster}>Mint NFT</a></Fragment>);
 
                    //await getMintAmountForCollectionNew(accountKeys[0].toBase58(), userWallet);

                    const amountOfSol =  (transactionData.accountData[0].nativeBalanceChange )/ LAMPORTS_PER_SOL;
                    const totalSol = amountOfSol - fee; // if sending, fee is deducted in the receiver amountOfSol + fee;

                    setAmount(<Fragment>{totalSol.toFixed(5)} SOL</Fragment>);
                    //setBalance( (transactionData.meta.postBalances[0]  / LAMPORTS_PER_SOL).toFixed(5) );

                    setIsFound(true);

                    if(transactionData.tokenTransfers[0].fromUserAccount === '') {
                        setTransactionType(<Fragment>Publish Collection</Fragment>);
                    }

                } else if(transactionData.type === 'TRANSFER') {

                    let description = transactionData.description.split(' ');;
                    if(transactionData.nativeTransfers.length > 0) {
                        //const amountOfSol = (transactionData.nativeTransfers[0].amount )/ LAMPORTS_PER_SOL ;
                        //const totalSol = amountOfSol - fee; // if sending, fee is deducted in the receiver amountOfSol + fee;



                        //setAmount(<Fragment>{totalSol.toFixed(5)} SOL</Fragment>);
                        //setTransactionType(<Fragment>Send SOL</Fragment>);
                        

                    }
                    setAmount(<Fragment>{description[2]} {description[3]}</Fragment>);
                    setTransactionType(<Fragment>Send {description[3]}</Fragment>);

                    setToWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://explorer.solana.com/address/'+description[5]+'?cluster='+ cluster}>{description[5]}</a>);
                    setFromWallet(<a target="_blank" className="viewTransaction" rel="noreferrer" href={'https://explorer.solana.com/address/'+description[0]+'?cluster='+ cluster}>{description[0]}</a>);
                
                    //setBalance( (transactionData.meta.postBalances[0]  / LAMPORTS_PER_SOL).toFixed(5) );

                    
                    setIsFound(true);
                }
 

                // if(signature === '2Tgk12xq3uF76GnuMGAQTxw8ySWeJPTQmhp3Xev4mSARC4w63TY6emyjLRN4zDY3FSXqfXMaLBaTuZSecvpoKLxN') {
                        

                   
                //     console.log(transactionData);

                //     for(var i in accountKeys) {
                //         console.log(i+'. '+accountKeys[i].toBase58());
                //     }
                // }
                

                    const date = new Date(transactionData.timestamp * 1000);
                    let txDate = date.toLocaleDateString('en-ca').replaceAll('-', '.');
                    setTxnDate(txDate);
                    //console.log(props.signature + "=" + txDate + "=");
                }catch(e) {
                        
                    // if(signature === '57yff54BUamQEt4bMWFetfYnes1JZHJMewJ1aeVQnfh3xHq2XZT3KFTg4AVneuTW3DArKHUqbVeTaL47Dr5G2cKt') {
                    //     console.log('here123');
                         console.log(signature);
                         console.log(e);
                        
                    //     console.log(await connection.getTransaction(signature, {
                    //         maxSupportedTransactionVersion : 0
                    //     }));

                    // }
                }
            }
        }

    }

    useEffect(() => {
        fetchLedgerInfo();
        //{props.signature}
    }, []);
    return (
        <Fragment>
            {
                isFound && txnDate != '' && props.type === 'desktop' && 
                <tr data-id={props.signature} className={fromWalletRevShare ? 'revshare-row' : ''}>
                    <td align="center">{txnDate}
                    </td>
                    <td align="center" className="ellipsis"><span>{fromWallet}</span>
                    {fromWalletRevShare ? fromWalletRevShare  : ''}
                    </td>
                    <td align="center" className="ellipsis"><span>{toWallet}</span>
                    {toWalletRevShare ? toWalletRevShare  : ''}
                    </td>
                    <td align="center">{transactionType}</td>
                    <td align="center">{amount}
                    {amountRevShare ? amountRevShare  : ''}
                    </td>
                    <td align="center">{ledgerType}
                    {ledgerTypeRevShare ? ledgerTypeRevShare  : ''}
                    </td>
                    
                </tr>

                
            }

            {
                isSoldToOwn && txnDate != '' && props.type === 'desktop' && 
                <tr data-id={props.signature} className={fromWalletRevShare ? 'revshare-row' : ''}>
                    <td align="center">{txnDate}
                    </td>
                    <td align="center" className="ellipsis"><span>{fromWallet}</span>
                    {fromWalletRevShare ? fromWalletRevShare  : ''}
                    </td>
                    <td align="center" className="ellipsis"><span>{toWallet}</span>
                    {toWalletRevShare ? toWalletRevShare  : ''}
                    </td>
                    <td align="center">{transactionTypeSoldToOwn}</td>
                    <td align="center">{amount}
                    {amountRevShareSoldToOwn ? amountRevShareSoldToOwn  : ''}
                    </td>
                    <td align="center">{ledgerType}
                    {ledgerTypeRevShare ? ledgerTypeRevShare  : ''}
                    </td>
                    
                </tr>

                
            }

            {
                isFound && txnDate != '' && props.type === 'mobile' && 
                
                <Fragment>

                    <table className="walletActivityTable" style={{ "borderSpacing" : 0}}>
                    <tbody>
                    <tr>
                        <td width="40%"><b>TIME</b></td>
                        <td width="60%">{txnDate}</td>
                    </tr>

                    <tr className={fromWalletRevShare ? 'revshare-row' : ''}>
                        <td width="40%"><b>FROM</b></td>
                        <td width="60%" className="ellipsis" ><span style={{ "left" : "0px", "width" : "100%"}}>{fromWallet}</span>
                        {fromWalletRevShare ? fromWalletRevShare  : ''}</td>
                    </tr>

                    <tr className={fromWalletRevShare ? 'revshare-row' : ''}>
                        <td width="40%"><b>TO</b></td>
                        <td width="60%" className="ellipsis" ><span style={{ "left" : "0px", "width" : "100%"}}>{toWallet}</span>
                        {toWalletRevShare ? toWalletRevShare  : ''}</td>
                    </tr>

                    <tr>
                        <td width="40%"><b>TYPE</b></td>
                        <td width="60%">{transactionType}</td>
                    </tr>

                    <tr>
                        <td width="40%"><b>AMOUNT</b></td>
                        <td width="60%">{amount}
                        {amountRevShare ? amountRevShare  : ''}</td>
                    </tr>

                    <tr>			
                        <td width="40%"><b>TRANSACTION</b></td>
                        <td width="60%">{ledgerType}
                        {ledgerTypeRevShare ? ledgerTypeRevShare  : ''}</td>
                    </tr>
                    </tbody>
                    </table>

                    <br />		

                </Fragment>

                
            }

            {
                isSoldToOwn && txnDate != '' && props.type === 'mobile' && 
                
                <Fragment>

                    <table className="walletActivityTable" style={{ "borderSpacing" : 0}}>
                    <tbody>
                    <tr>
                        <td width="40%"><b>TIME</b></td>
                        <td width="60%">{txnDate}</td>
                    </tr>

                    <tr className={fromWalletRevShare ? 'revshare-row' : ''}>
                        <td width="40%"><b>FROM</b></td>
                        <td width="60%" className="ellipsis" ><span style={{ "left" : "0px", "width" : "100%"}}>{fromWallet}</span>
                        {fromWalletRevShare ? fromWalletRevShare  : ''}</td>
                    </tr>

                    <tr className={fromWalletRevShare ? 'revshare-row' : ''}>
                        <td width="40%"><b>TO</b></td>
                        <td width="60%" className="ellipsis" ><span style={{ "left" : "0px", "width" : "100%"}}>{toWallet}</span>
                        {toWalletRevShare ? toWalletRevShare  : ''}</td>
                    </tr>

                    <tr>
                        <td width="40%"><b>TYPE</b></td>
                        <td width="60%">{transactionTypeSoldToOwn}</td>
                    </tr>

                    <tr>
                        <td width="40%"><b>AMOUNT</b></td>
                        <td width="60%">{amount}
                        {amountRevShareSoldToOwn ? amountRevShareSoldToOwn  : ''}</td>
                    </tr>
                    <tr>			
                        <td width="40%"><b>TRANSACTION</b></td>
                        <td width="60%">{ledgerType}
                        {ledgerTypeRevShare ? ledgerTypeRevShare  : ''}</td>
                    </tr>
                    </tbody>
                    </table>

                    <br />		

                </Fragment>

                
            }
        </Fragment>

    )

}

export default LedgerItemNew;