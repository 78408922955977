import {loadStripeOnramp} from '@stripe/crypto';

import {CryptoElements, OnrampElement} from './StripeCryptoElements';
import { stripe_pub_key } from './constants';

const stripeOnrampPromise = loadStripeOnramp(stripe_pub_key);
   

const OnRamp = (props : any) => {
  // IMPORTANT: replace with your logic of how to mint/retrieve client secret
  
  return (props.secret && 
            <CryptoElements stripeOnramp={stripeOnrampPromise}>
                <OnrampElement clientSecret={props.secret} showSuccessPayment={props.showSuccessPayment} />
            </CryptoElements>
  
  );
}
export default OnRamp;