import { Fragment, useEffect, useRef, useState } from "react";
import { backend_api_url, checkUserBalanceForMint, chunkArray, cluster, getClientOnRampSecret, getClientStripeSecret, getPrivateKeyBase58, getUserBalance, getUserSessionData, mintNewGacha, saveUserReload, stripe_pub_key } from "./constants";
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import WalletLink from "./WalletLink";
import { Keypair } from "@solana/web3.js";
import { Metaplex, PublicKey, keypairIdentity } from "@metaplex-foundation/js";
import { useConnection } from "@solana/wallet-adapter-react";
import axios from "axios";
import RecentlyMinted from "./RecentlyMinted";
import OnRamp from "./OnRamp";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";

const ViewGacha = (props: any) => {

    let params = useParams();

    const [allNftImages, setAllNftImages] = useState<string[] | any>();
    const [contentVal, setContentVal] = useState<string>('');
    const [candyMachineAddress, setCandyMachineAddress] = useState<string>('');
    const [avatar, setAvatar] = useState<string>('');
    const [banner, setBanner] = useState<string>('');
    const [userInfo, setUserInfo] = useState<any>(null);
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [nftCount, setNftCount] = useState<string>('');
    const [price, setPrice] = useState<string>('');
    const [tokenAccepted, setTokenAccepted] = useState<string>('');
     
    const [isReadyMint, setIsReadyMint] = useState(0);
    const [itemsInfoTextDisplay0, setItemsInfoTextDisplay0] = useState('');
    const [itemsInfoTextDisplay1, setItemsInfoTextDisplay1] = useState('');
    const [itemsInfoTextDisplay2, setItemsInfoTextDisplay2] = useState('');

    const [numOfDesigns, setNumOfDesigns] = useState(0);

    const [edition, setEdition] = useState<string>('');
    const [numOfMinted, setNumOfMinted] = useState(0);
    const [mintedText, setMintedText] = useState('On Sale');

    const [numCredits, setNumCredits] = useState(0);

    const [balance, setBalance] = useState<number>(0);

    const [usernameJsx, setUsernameJsx] = useState<JSX.Element>(null);
    const [isRefresh, setIsRefresh] = useState(false);

    
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [gachaEdition, setGachaEdition] = useState<string>('');
    const [gachaWallet, setGachaWallet] = useState<string>('');
    const [collectionName, setCollectionName] = useState<string>('');

    
    const [showGacha, setShowGacha] = useState(false);
    const handleCloseGacha = () => setShowGacha(false);
    const handleShowGacha = () => setShowGacha(true);

    const [mintImage, setMintImage] = useState<string>('/images/mint-button.png');


    const [showPreview, setShowPreview] = useState(false);
    const handleClosePreview = () => setShowPreview(false);
    const handleShowPreview = () => setShowPreview(true);

    const { connection } = useConnection();

    const [previewImageName, setPreviewImageName] = useState<JSX.Element>(null);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const [previewImageDesc, setPreviewImageDesc] = useState("");
    const [previewCollectionName, setPreviewCollectionName] = useState<JSX.Element>(null);
    const [propertiesJsx, setPropertiesJsx] = useState<JSX.Element[] | null>(null)
    const [usernameJsx1, setUsernameJsx1] = useState<JSX.Element>(null);

    const [mintError, setMintError] = useState("");
    const [mintErrorHeader, setMintErrorHeader] = useState("");

    
    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);
    
    const [secret, setSecret] = useState<string>('');
    const [showStripe, setShowStripe] = useState(false);
    const handleCloseStripe = () => setShowStripe(false);
    const handleShowStripe = () => setShowStripe(true);
 
    const [showBuyCrypto, setShowBuyCrypto] = useState(false);

    const [reloadBalance, setReloadBalance] = useState("100");
    const amountInput = useRef<HTMLInputElement>(null);

    const [showStripeReloadAmount, setShowStripeReloadAmount] = useState(false);
    const handleCloseStripeReloadAmount = () => setShowStripeReloadAmount(false);
    const handleShowStripeReloadAmount = () => setShowStripeReloadAmount(true);


    const [showClear, setShowClear] = useState(false);
    const handleCloseClear = () => setShowClear(false);
    const handleShowClear = () => setShowClear(true);

    const stripePromise = loadStripe(stripe_pub_key);
  
    const options = {
        clientSecret: secret,
    };

    const openStripe = async () => {

        const resp = await checkUserBalanceForMint(candyMachineAddress);

        if(resp.hasBalance === 1) {

            openStripeNow(); 
            
        } else {

            await getUserBalance(setBalance);
            handleShowStripeReloadAmount();

        }
    }
    const openStripeNow1 = async () => {
        setReloadBalance(amountInput.current!.value);
        await openStripeNow();
    }
    const openStripeNow = async () => {

        const resp = await getClientStripeSecret(candyMachineAddress, amountInput.current ? amountInput.current!.value : 10);

        if(resp.availableForMint === 1) {
            if(resp.hasBalance === 1) {

                showSuccessPayment();
                await mintGachaNow('');
                
            } else {
                handleCloseStripeReloadAmount();
                setSecret(resp.clientSecret); 
                handleShowStripe();
            }
        } else if(resp.availableForMint === 2) {
            let errorMessage = 'Toy Machine is not ready to mint.';
            let header = 'Notification';
            
            setMintErrorMessage(errorMessage, header);
        } else {
            let errorMessage = 'Sold Out. All NFTs have been minted.';
            let header = 'Sold Out';
            
            setMintErrorMessage(errorMessage, header);

        }
    }

    const reloadUserBalanceSave = async(txnId) => {
        await saveUserReload(reloadBalance, txnId);
        await openStripe();
        // await mintGachaNow('');

    } 
    
    const showSuccessPayment = () => {
      handleCloseStripe();
        // setMintErrorHeader('Notification');
        // setMintError('Payment Successful');
    //   handleShowError(); 
        // setMintErrorMessage("Payment Successful", "Notification");
    }

    const showMintedNft = async (resp) => {
        
        if(resp) {
            /*
            const keypair = Keypair.generate();
      
            const metaplex = new Metaplex(connection);
            metaplex.use(keypairIdentity(keypair));
         
            const mintAddress = new PublicKey(address);
            const dataInfo = await metaplex.nfts().findByMint({ mintAddress  });
               
            showNftData(address, dataInfo);
             */
            showNftDataNew(resp);
        }

    }

    const showCollectionLink = async(collectionAddress, collectionName, address, nftName) => {

        const params = {
            address: collectionAddress
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/get-info-by-mint-address', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                     
                    setPreviewCollectionName(<a href={"/collection/" + data.address}>{data.name}</a>);

                    setPreviewImageName(<a target="_blank" className="all-caps"  rel="noreferrer" href={'https://explorer.solana.com/address/'+address+'?cluster='+ cluster}>{ data.name.toUpperCase() + ' - ' + nftName.toUpperCase()}</a>);

                }else{
                    //not in the system, so display address to external url 
                    setPreviewCollectionName(<Fragment>{collectionName}</Fragment>);

                }
            });
            return;
    }

    const showCollectionLinkNew = async(collectionAddress, collectionName, address, nftName) => {

        setPreviewCollectionName(<a href={"/collection/" + collectionAddress}>{collectionName}</a>);
        setPreviewImageName(<a target="_blank" className="all-caps"  rel="noreferrer" href={'https://explorer.solana.com/address/'+address+'?cluster='+ cluster}>{ collectionName.toUpperCase() + ' - ' + nftName.toUpperCase()}</a>);

        /*
        const params = {
            address: collectionAddress
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/get-info-by-mint-address', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                     
                    setPreviewCollectionName(<a href={"/collection/" + data.address}>{data.name}</a>);

                    setPreviewImageName(<a target="_blank" className="all-caps"  rel="noreferrer" href={'https://explorer.solana.com/address/'+address+'?cluster='+ cluster}>{ data.name.toUpperCase() + ' - ' + nftName.toUpperCase()}</a>);

                }else{
                    //not in the system, so display address to external url 
                    setPreviewCollectionName(<Fragment>{collectionName}</Fragment>);

                }
            });
            return;
            */
    }
      
    const showNftData = (address, dataInfo) => {
        
                let nftName = dataInfo.name.split(".")[0];


                showCollectionLink(dataInfo.collectionAddress, dataInfo.collectionName, address, nftName);

                setPreviewImageDesc(dataInfo.json.description);

                setPreviewImageName(<a target="_blank"  rel="noreferrer" href={'https://explorer.solana.com/address/'+address+'?cluster='+ cluster}>{nftName}</a>);
                setPreviewImageUrl(dataInfo.json.image);

                //setUsernameJsx(<a href={"/user/" + userData1.username}>{userData1.username}</a>);
 
                let userData = getUserSessionData();
 
                setUsernameJsx1(<a href={"/user/" + userData.username}>{userData.username}</a>);


                if(dataInfo.json.attributes) {
                    const attributes = Array.isArray(dataInfo.json.attributes) ? dataInfo.json.attributes : JSON.parse(dataInfo.json.attributes);  
                    const properties = chunkArray(attributes, 2);
                    const chunkedJSX = [];

                    for (let i = 0; i < properties.length; i ++) {
                        let elem = properties[i];

                        if(elem.length === 2) {
                            chunkedJSX.push(
                                <div className='row-elem' key={i}>
                                    {elem[0] &&  <div className="custom-column">{elem[0].trait_type}: <span className="prop-value">{elem[0].value}</span></div>} 
                                    {elem[1] &&  <div className="custom-column">{elem[1].trait_type}: <span className="prop-value">{elem[1].value}</span></div>} 
                                </div>
                            );
                        }else{
                            chunkedJSX.push(
                                <div className='row-elem' key={i}>
                                    {elem[0] &&  <div className="custom-column">{elem[0].trait_type}: <span className="prop-value">{elem[0].value}</span></div>} 
                                </div>
                            );
                        }
                    }
                    setPropertiesJsx (chunkedJSX);
                } 
        handleShowPreview(); 
    }

    const showNftDataNew = (resp) => {
        
        // resp.dataInfo.metadata_uri
        let nftName = resp.dataInfo.name.split(".")[0];

        let address = '';

        showCollectionLinkNew(resp.dataInfo.collectionAddress, resp.dataInfo.collectionName, address, nftName);

        //setPreviewImageName(<a target="_blank"  rel="noreferrer" href={'https://explorer.solana.com/address/'+address+'?cluster='+ cluster}>{nftName}</a>);
        setPreviewImageName(<div>{nftName}</div>);
        setPreviewImageUrl(resp.dataInfo.image_uri);

        let userData = getUserSessionData();

        setUsernameJsx1(<a href={"/user/" + userData.username}>{userData.username}</a>);

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({})
        };
        fetch(resp.dataInfo.metadata_uri, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.attributes) {
                        //console.log('show att');
                        const attributes = Array.isArray(data.attributes) ? data.attributes : JSON.parse(data.attributes);  
                        //console.log(attributes);
                        const properties = chunkArray(attributes, 2);
                         
                        const chunkedJSX = [];
    
                        for (let i = 0; i < properties.length; i ++) {
                            let elem = properties[i];
    
                            if(elem.length === 2) {
                                chunkedJSX.push(
                                    <div className='row-elem' key={i}>
                                        {elem[0] &&  <div className="custom-column">{elem[0].trait_type} : <span className="prop-value">{elem[0].value}</span></div>} 
                                        {elem[1] &&  <div className="custom-column">{elem[1].trait_type} : <span className="prop-value">{elem[1].value}</span></div>} 
                                    </div>
                                );
                            }else{
                                chunkedJSX.push(
                                    <div className='row-elem' key={i}>
                                        {elem[0] &&  <div className="custom-column">{elem[0].trait_type} : <span className="prop-value">{elem[0].value}</span></div>} 
                                    </div>
                                );
                            }
                        }
                        setPropertiesJsx (chunkedJSX);                        
                }
                if(data.description) {
                    setPreviewImageDesc(data.description);
                }
            });

        
    handleShowPreview(); 
    }

    const  mintGacha = async(e) => {
        //props.loginWeb3Auth();
        if(getUserSessionData() == null || props.provider == null) {
            props.loginWeb3Auth();
            return;
        }
        handleShowClear();
        //confirmMint();
        
    }

    const confirmMint = () => {
        handleCloseClear();
        openStripe();
    }
    const mintGachaNow = async(paymentIntentId: any) => {

        //return;

        setShowBuyCrypto(false); 
        
        setIsRefresh(false);

        const web3authKey = await getPrivateKeyBase58(props.provider);
  
        const userData = getUserSessionData();

        const params1 = {
            //creator : web3authKey, //publisher.toBase58(),
            candyMachineAddress: candyMachineAddress,
            //edition: gachaEdition,
            //gachaWallet: gachaWallet,
            receiver: web3authKey,
            paymentIntentId: paymentIntentId
            //currentWallet: userData.wallet
        }; 
        handleShow();
        const isSuccessData = await mintNewGacha(params1, params.showToastError, setMintErrorMessage, setShowBuyCrypto, handleClose, showSuccessfulMint);
        /*
        handleClose();
        if(isSuccessData) {
            showMintedNft(isSuccessData);
            setIsRefresh(true);

        }
        */
    }

    const showSuccessfulMint = (successData) => {
        showMintedNft(successData);
        setIsRefresh(true);
    }

    const setMintErrorMessage = (message, header) => {
        if(message) {
            setMintError(message);
            setMintErrorHeader(header)
            handleClose();
            handleShowError();
        }
    }

    const viewGacha = async (address): Promise<any> => {
        setCandyMachineAddress(address);
        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        let params = {
            token: userData ? userData.token : '',
            secret: userData ? userData.secret : '',
            candy_machine_address: address
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/info', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    setIsReadyMint(data.gacha.is_ready_mint);
                    setItemsInfoTextDisplay0(data.itemsInfoTextDisplay0);
                    setItemsInfoTextDisplay1(data.itemsInfoTextDisplay1);

                    setItemsInfoTextDisplay2(data.itemsInfoTextDisplay2);

                    setContentVal(data.gacha.description);
                    setAvatar(data.gacha.avatar);
                    setNftAssets(data.nfts);
                    setGachaEdition(data.gacha.edition)
                    //setGachaWallet(data.gacha.gacha_wallet_priv_key.substr(3));
                    setBanner(data.gacha.banner_photo);
                    setCollectionName(data.gacha.name)
                    setUserInfo(data.user);
                    setShowUserInfo(true);
                    setNftCount(data.collectionSize);
                    setEdition(data.gacha.edition.toUpperCase());
                    setPrice(data.gacha.price);
                    setNumCredits(parseInt(data.gacha.price, 10));
                    setTokenAccepted(data.gacha.token_accepted);
                    setNumOfMinted(data.numOfMinted);

                    if(data.numOfMinted === data.collectionSize) {
                        setMintedText("SOLD OUT");
                    } else {
                        if(data.is_admin == 1) {
                            setMintedText("ON SALE – MINTED: " + data.numOfMinted)
                        } else {
                            setMintedText("ON SALE")

                        }
                    } 
                    setNumOfDesigns(data.nftDesigns);
                    setUsernameJsx(<a href={"/user/" + data.user.username}>{data.user.username}</a>);
 
                    if(data.is_admin === 0 && data.gacha.is_available === 0) {
                        if(data.is_same_user === 0)
                            window.location.href = "/";
                    }
                }
            });
	};

    

    const setNftAssets = async (nfts : any) => {
        //console.log(nfts);

        
        // const allImages = await Promise.all(
		// 	nfts.map((asset: any) => {
        //         return {'url': asset.arweave_url, 'name' : asset.name};
                
                
		// 	})
 

		// );
        
            setAllNftImages(nfts)

    }

    const updateImage = () => {
        setMintImage("/images/mint.gif");
    }
    const resetImage = () => {
        setMintImage("/images/mint-button.png");
    }
    const showDesigns = async () => {
        // console.log("Show all Designs " + params.address);
        handleShowGacha();
    }
  

    useEffect(() => {
        if(params.address) {
            viewGacha(params.address);

        }
    }, []); 

    props.setClassInfo("collectionsPg");

    return (
        <Fragment>

<div id="MintBanner">
        {
            isReadyMint === 0 && <div className='info-overlay'>{itemsInfoTextDisplay0}<br /><br />{itemsInfoTextDisplay1}<br /><br />{itemsInfoTextDisplay2}<br /></div>
        }
		<img className="view-banner-image" src={banner} alt="" />

		<div id="MintBannerDiv">
           <div className="collectionLinks">
			  <a href="#edition" className="buttonLink href-no-hover">{edition} EDITION</a> 
			  <a href="#price" className="buttonLink href-no-hover">PRICE: ${price}</a>
			  <a href="#collectionSize" className="buttonLink href-no-hover">COLLECTION SIZE ({nftCount})</a>
			  <a href="#minted" className="buttonLink href-no-hover">{mintedText}</a>
		   </div>

		   <div className="mintButton">
			  {/* <a href="javascript:;" onClick={mintGacha}><img src="/images/mint-button.png" /></a>  */}
              <img onMouseOver={updateImage} onMouseOut={resetImage}  src={mintImage} className="mint-button" onClick={mintGacha} alt=""/>
		   </div>
		</div>
	  </div>
	  
	  <div className="contents">

		<div className="rightSideContent collectionDetails">
		  
		  <div className="leftSideDiv">
			<a href="#"><img alt="" src={avatar}/></a>
		  </div>	
		  
		  <h5>{collectionName}</h5>

		  <p><b>CREATOR : </b>{usernameJsx}</p>

		  <p><b>ABOUT : </b><span className="about-us" dangerouslySetInnerHTML={{ __html: contentVal }} /></p>

          <p><a href="#designs" onClick={showDesigns}>See Designs ({numOfDesigns})</a></p>
		  <br/>
		  {/* <p><a href="/wallet">Wallet Address</a> : {<span className="account-wallet"><WalletLink loginWeb3Auth={props.loginWeb3Auth} provider={props.provider} isExternal={false}/></span>}</p> */}
		</div>
		  
		<div className="collections recentlyMinted">
		  <h5>RECENTLY MINTED</h5>

		  {/* <div id="ImageSlider">
			<div id="ImageSliderDiv" className="slider slider-minted">

                {
                    candyMachineAddress && <RecentlyMinted candyMachineAddress={candyMachineAddress} username='' useCarousel={false}></RecentlyMinted>
                }
                

			</div>
		   </div>   */}
		  
           {
                    candyMachineAddress && <RecentlyMinted isRefresh={isRefresh} candyMachineAddress={candyMachineAddress} username='' limit="8" useCarousel={false} isViewGacha={true}></RecentlyMinted>
                }
		</div>

		<h5 className="outer">ABOUT THE CREATOR</h5> 

		<div className="rightSideContent aboutCreator">
            { showUserInfo &&
            <Fragment>
                <div className="leftSideDiv">
                    
                        <a href={'/user/'+userInfo.username}><img alt="" src={userInfo.avatar}/></a>
                    
                </div>
                
                <p><span className="about-us" dangerouslySetInnerHTML={{ __html: userInfo.about }} /></p>
          </Fragment>
            }
		</div>

	  </div>

                 
        <Modal className="modal-processing" show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton={false}>
            <Modal.Title>Processing...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
 
            <div className="progressBar"><div></div></div>
 
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 

        <Modal className="modal-error" show={showError} onHide={handleCloseError} backdrop="static">
            <Modal.Header closeButton={true}>
            <Modal.Title>{mintErrorHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
 
                <div>
                    {mintError}
                </div>
 
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 

        <Modal className="modal-preview" show={showGacha} onHide={handleCloseGacha}
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title>Designs</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
                <div>
                    <div>
                        <div className="uploadPatternDiv gacha-designs" >

                        {
                            allNftImages && allNftImages.map( (asset , index : number) => {
                                return asset && <div key={index + (new Date()).getTime()}><img className='asset-image-new 12' alt=""  src={asset.arweave_url}/><br />{collectionName} - {asset.nft_name.split(".")[0]}</div>;
                            })
                        }
                            
                        </div>
                        
                        
                        <div className="sendNftName" style={{"textAlign" : "center"}}>
                            <button onClick={handleCloseGacha} className="buttonLink btn-generic">Close</button>
                        </div>
                    </div>
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>


    <Modal className="modal-preview" show={showPreview} onHide={handleClosePreview}
  size="sm"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton={true}>
        <Modal.Title>{previewImageName}</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
                <div id='NftDetail' style={ {"padding" : "10px", "background" : '#ffffff', "margin" : "0px"} }>
                    <img alt="" src={previewImageUrl}/>
                    <br/><br/>
                    <p>OWNED BY : <span>{usernameJsx1}</span></p>
                    { previewCollectionName && <Fragment><p className="pull-left">COLLECTION : {previewCollectionName}</p></Fragment>}
                     
                    <p className="pull-left">DESCRIPTION : <span className="full-text" dangerouslySetInnerHTML={{ __html: previewImageDesc }} /></p>
                    

                    <p className="pull-left">PROPERTIES<br/></p>
                        <div className="custom-row">
                            {
                                propertiesJsx ? propertiesJsx : 'N/A'
                            }
                        </div> 
                    <br />
 
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>
      
    <Modal className="modal-payment" size="sm" show={showStripeReloadAmount} onHide={handleCloseStripeReloadAmount} backdrop="static">
        <Modal.Header closeButton={true}>
        <Modal.Title>Buy Credits
        </Modal.Title>
        </Modal.Header>
            <Modal.Body>
            <div style={{ "textAlign" : "center"}}>

                <div id='SendToken'>
                <span style={{"fontSize":"12px", "position" : "relative", "top" : "-10px"}}>You currently have {balance.toFixed(0)} credit(s). 1 credit = $1</span>
                        <form name="frmSendTokens" id="frmSendTokens" method="get" action="#">

                            <table style={ {"border" : 0}} className="sendTokenTable">
                            <tbody>
                            <tr>
                                <td align="right">Amount</td>
                                
                                <td align="left">
                                <input type="text" className="textbox" required ref={amountInput} defaultValue={100} />
                                </td>	 
                            </tr>

                        
                            </tbody>
                            </table>
                        </form>
                        <button className="buttonLink btn-generic" onClick={openStripeNow1} >PURCHASE NOW</button>
                </div> 

            </div>

            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
    </Modal>
    
    <Modal className="modal-payment" size="sm" show={showStripe} onHide={handleCloseStripe} backdrop="static">
        <Modal.Header closeButton={true}>
        {/* <Modal.Title>Notification</Modal.Title> */}
        </Modal.Header>
            <Modal.Body>
            <div style={{ "textAlign" : "center"}}>
                {
                    secret && 
                    <Elements options={options} stripe={stripePromise}>
                        <div style={{"textAlign": "left"}}>Buy Credits: ${reloadBalance}</div>
                        <CheckoutForm showSuccessPayment={showSuccessPayment} mint={reloadUserBalanceSave}/>
                    </Elements> 
                }
            </div>

            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
    </Modal>

    <Modal  className="modal-error"  show={showClear} onHide={handleCloseClear} backdrop="static">
          <Modal.Header closeButton={true}>
          <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <div style={{ "textAlign" : "center"}}>
              Are you sure you want to mint this NFT for {numCredits} credits?
              </div>

              <div style={{ "textAlign" : "center", "marginTop" : "30px"}}> 
                <button onClick={handleCloseClear} className="buttonLink btn-generic">CANCEL</button>

                <button onClick={confirmMint} className="buttonLink btn-generic">CONFIRM</button>
              </div>
              
          
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
      </Modal>

        </Fragment>
    );
}

export default ViewGacha;