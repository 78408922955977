import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import { backend_api_url, cluster, getAsset, getAssetProof, getAssetsByOwner, getBalance, getClientOnRampSecret, getClientStripeSecret, getClientStripeSecretReload, getCollectionNameByAssetId, getConnectionConfig, getPrivateKeyBase58, getPublisherNew, getSignaturesForAsset, getUsdcMintAddress, getUsdcTokenBalance, getUserBalance, getUserSessionData, saveUserReload, settingsSlider, stripe_pub_key} from "./constants";
import { AccountMeta, PublicKey , Keypair, LAMPORTS_PER_SOL,  Connection, clusterApiUrl, GetProgramAccountsFilter, sendAndConfirmTransaction, ComputeBudgetProgram } from "@solana/web3.js";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { ToastContainer, toast } from 'react-toastify';
import { getCurrentWalletPublicKey} from './constants';

import WalletLink from "./WalletLink"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SystemProgram, Transaction } from '@solana/web3.js';

import { SolanaWallet } from "@web3auth/solana-provider";

     
import { getOrCreateAssociatedTokenAccount  } from "@solana/spl-token";

import { Metaplex, keypairIdentity } from "@metaplex-foundation/js";
import axios from "axios";
import RecentlyMinted from "./RecentlyMinted";
import Slider from "react-slick";
import * as splToken from "@solana/spl-token";
import OnRamp from "./OnRamp";
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { PROGRAM_ID as MPL_BUBBLEGUM_PROGRAM_ID } from '@metaplex-foundation/mpl-bubblegum'
//import { publicKey } from '@metaplex-foundation/umi';
//import { mplBubblegum } from '@metaplex-foundation/mpl-bubblegum';
//import { publicKeyBytes } from '@metaplex-foundation/umi'

import { createTransferInstruction } from "@metaplex-foundation/mpl-bubblegum";
 
import {
  ConcurrentMerkleTreeAccount,
  SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
  SPL_NOOP_PROGRAM_ID,
} from "@solana/spl-account-compression";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import base58 from "bs58";

//const { Token, TOKEN_PROGRAM_ID, Account, Transaction, SystemProgram } = require('@solana/spl-token');

const WalletInfo = (props : any) => {
 
    const amountInput = useRef<HTMLInputElement>(null);
    const addressInput = useRef<HTMLInputElement>(null);
 
    const [show, setShow] = useState(false);

    const [showNftSend, setShowNftSend] = useState(false);
    const handleShowNftSend = () => setShowNftSend(true);

    const handleShow = () => setShow(true);

    //const [solParams, setSolParams] = useState(null);

    const { connection } = useConnection();

 
    const [ledgerJsx, setLedgerJsx] = useState<JSX.Element[] | null>(null)

    const [allNfts, setAllNfts] = useState<any[]>(null)

    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)
    const [listingJsxByCollection, setListingJsxByCollection] = useState<JSX.Element[] | null>(null)

    const [showBy, setShowBy] = useState("recent");

    const [showPreview, setShowPreview] = useState(false);
    const handleClosePreview = () => setShowPreview(false);
    const handleShowPreview = () => setShowPreview(true);

    const [showPreviewSend, setShowPreviewSend] = useState(false);
    const handleClosePreviewSend = () => setShowPreviewSend(false);
    const handleShowPreviewSend = () => setShowPreviewSend(true);

    const [showNeedSol, setShowNeedSol] = useState(false);
    const handleCloseNeedSol = () => setShowNeedSol(false);
    const handleShowNeedSol = () => setShowNeedSol(true);

    const [reloadBalance, setReloadBalance] = useState("100");

    const [balance, setBalance] = useState<number>(0);

    
    const tokenTypeInput = useRef<any>(null);
    const solAmountInput = useRef<any>(null);
    const toAddressInput = useRef<any>(null);

    const toAddressNftInput = useRef<any>(null);

    const [username, setUsername] = useState("");
    const [usernameJsx, setUsernameJsx] = useState<JSX.Element>(null);

    const [previewImageName, setPreviewImageName] = useState<JSX.Element>(null);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const [previewImageDesc, setPreviewImageDesc] = useState("");
    const [previewCollectionName, setPreviewCollectionName] = useState<JSX.Element>(null);
    const [selectedNftAddress, setSelectedNftAddress] = useState("");
    const [selectedNftType, setSelectedNftType] = useState("");

    const [selectedNftMintAddress, setSelectedNftMintAddress] = useState("");


    const [num, setNum] = useState(0)
    const [propertiesJsx, setPropertiesJsx] = useState<JSX.Element[] | null>(null)

    const [sendTokenTitle, setSendTokenTitle] = useState("");
    const [selectedTokenType, setSelectedTokenType] = useState("");
    const [destinationWallet, setDestinationWallet] = useState("");
    const [numOfToken, setNumOfToken] = useState("");

    const [sendNftTitle, setSendNftTitle] = useState("");
    const [sendTokenErrorMessage, setSendTokenErrorMessage] = useState("");
    const [sendNftErrorMessage, setSendNftErrorMessage] = useState("");

    

    const [renderedElements, setRenderedElements] = useState([]);
    const [renderedElementsNew, setRenderedElementsNew] = useState([]);

    const [byRecentNft, setByRecentNft] = useState([]);
    const [byCollectionNft, setByCollectionNft] = useState([]);


    const [currentIndex, setCurrentIndex] = useState(0);


    const [secretReload, setSecretReload] = useState<string>('');

    const [secret, setSecret] = useState<string>('');
    const [showStripe, setShowStripe] = useState(false);
    const handleCloseStripe = () => setShowStripe(false);
    const handleShowStripe = () => setShowStripe(true);

    const [showStripeReload, setShowStripeReload] = useState(false);
    const handleCloseStripeReload = () => setShowStripeReload(false);
    const handleShowStripeReload = () => setShowStripeReload(true);

    const [showStripeReloadAmount, setShowStripeReloadAmount] = useState(false);
    const handleCloseStripeReloadAmount = () => setShowStripeReloadAmount(false);
    const handleShowStripeReloadAmount = () => setShowStripeReloadAmount(true);

    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const [showSuccess, setShowSuccess] = useState(false);
    const handleCloseSuccess = () => setShowSuccess(false);
    const handleShowSuccess = () => setShowSuccess(true);
    
    
    const [mintError, setMintError] = useState("");
    const [mintErrorHeader, setMintErrorHeader] = useState("");

    const [hideInitial, setHideInitial] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);

    const config = getConnectionConfig(); 

    const umi = createUmi(config.rpcTarget); 
    //umi.use(mplBubblegum());


    const [currentWallet, setCurrentWallet] = useState("");
    const [hiddenMachine, setHiddenMachine] = useState<any[]>(null)

    const stripePromise = loadStripe(stripe_pub_key);

    const [isPin, setIsPin] = useState(false);
    

    const options = {
        clientSecret: secretReload,
    };


    const openStripe = async () => {
        handleShowStripeReloadAmount();
    }

    const openStripeNow = async () => {
        setReloadBalance(amountInput.current!.value);

        const resp = await getClientStripeSecretReload(amountInput.current!.value);

        if(resp.clientSecret) {
            handleCloseStripeReloadAmount();
            setSecretReload(resp.clientSecret);
            // console.log(clientSecret);
            handleShowStripeReload();
        }
    }

    const openOnRamp = async () => {


        const clientSecret = await getClientOnRampSecret('SOL');
        setSecret(clientSecret);
        //console.log(clientSecret);
        handleShowStripe();

        //console.log(getUserSessionData());

    }

    const showSuccessPayment = () => {
        handleCloseStripe();
        setMintErrorHeader('Notification');
        setMintError('Payment Successful');
        handleShowError();
        setTimeout(doLoadBalance, 2000);

    }

    const showSuccessPaymentReload = () => {
        handleCloseStripeReload();
        setMintErrorHeader('Notification');
        setMintError('Payment Successful');
        handleShowError();
        setTimeout(reloadUserBalance, 500);

    }
    
     

    // const getUsdcToken = async () => {

    //         const usdcAmount = await getUserBalance();
    //         setUsdcToken(usdcAmount);
    // }
   
    const reloadUserBalanceSave = async(txnId) => {
        await saveUserReload(reloadBalance, txnId);
        await getUserBalance(setBalance);

    }
    const reloadUserBalance = async() => {
        await getUserBalance(setBalance);

    }

    useEffect(() => { 
        const userData1 = getUserSessionData();
        if(userData1) {
            //console.log("props.loginWeb3Auth();");
            props.loginWeb3Auth();
            //loadAllNft(wallet, hiddenMachines);
            viewNftsOwned(userData1.username);
            reloadUserBalance();
            //getUsdcToken();  
            setUsername(userData1.username);
            setUsernameJsx(<a href={"/user/" + userData1.username}>{userData1.username}</a>);
            
        }else{
            window.location.href = "/";
        }

    }, []);

 
    const viewNftsOwned = async (username) => {
        const params = {
            username: username
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/user-wallet', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                     
                    localStorage.setItem("pinnedNfts", JSON.stringify ( data.pinnedNft) );

                    setHiddenMachine (data.xMachines);
                    setCurrentWallet (data.userInfo.primary_wallet) ;
                    loadAllNft(data.userInfo.primary_wallet, data.xMachines);
                }
            });
    }

    

    const getTransactionInfo = async(signature) => {
        //let info = null;
         
        const transactionData = await connection.getTransaction(signature, {
          maxSupportedTransactionVersion : 0
        });
    
        //info =  {'signature' : signature, 'transaction' : };
        if(!transactionData) {
            return [];
        }

        return [transactionData];
    }

    const loadAllNft = async (wallet, hiddenMachines) => {
        setIsProcessing(true);
        
      
        const allNFTs = await getAssetsByOwner(wallet);
 
        var data = Object.keys(allNFTs).map((key) => allNFTs[key]);                                                                    
        
        const newArr = await Promise.all(
            data.map(async (asset: any) => {
                let resp = false;
                 
                if(selectedNftAddress !== '' && selectedNftAddress === asset.mintAddress) {
                    return resp;
                }
                if(asset.uri && asset.uri.indexOf('http') >= 0) {
                    let timestamp = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getTime();
                    let transactions = [];
    
                    //console.log(data[i]);
                    let collectionName = ''; 
    
                    if(asset.type === 'cnft') {
                        collectionName = 'temp-name'; // await getCollectionNameByAssetId(asset.assetId);
                    } 
                    if(hiddenMachines.length === 0 || !hiddenMachines.includes(asset.collectionAddress)) {
                        
                        return ({
                            uri : asset.uri,
                            address: asset.mintAddress,
                            type: asset.type,
                            extraInfo: asset,
                            collectionAddress: asset.collectionAddress,
                            timestamp: timestamp,
                            date: new Date(timestamp),
                            hasTransaction: transactions,
                            collectionName : collectionName
                        });
                    }
                }

                return resp;
            })
        );
        //console.log(newArr);
        const newArr1 = [];
        for(var i in newArr) {
            if(newArr[i]) {
                newArr1.push(newArr[i])
            }
        }
        //console.log(newArr1);

        // return arr;
        loadAllNftImages(newArr1);
    }

    const sendBalanceNow = async() => {
        //console.log('sendBalanceNow');

        
        props.loginWeb3Auth();

        if(getUserSessionData() == null || props.provider == null) {
            props.loginWeb3Auth();
            return;
        }
        
        const tokenType = (tokenTypeInput.current!.value);
        const amount = (solAmountInput.current!.value);
        const toWallet = (toAddressInput.current!.value);
        
        
        const params = {
            amount: amount,
            address: toWallet.trim()
        }
        //console.log(params);

        const userData = getUserSessionData();
        if(userData) {
            
            if(userData.wallet) {
                const pubkey =  new PublicKey(userData.wallet);
                let balance = 0;

                if(tokenType === 'usdc') {
                    //balance = await getUsdcTokenBalance(connection);
                }else if(tokenType === 'sol') {
                    balance = await getBalance(pubkey, connection);
                }
                
                if(balance < parseFloat(params.amount)) {
                    setSendTokenErrorMessage("Amount is greater the wallet balance.");
                    return false;
                }

                //check if the address is correct
                try{


                    // code for error testing
                    const accountInfo = await connection.getAccountInfo(new PublicKey(params.address));
                    //console.log(accountInfo);

                    params['accountInfo'] = accountInfo;
                    params['fromWallet'] = new PublicKey(userData.wallet);
                    params['toWallet'] = new PublicKey(params.address);
                    params['type'] = tokenType;


                    //setSolParams(params);

                    await sendAmountNow(params);
                    //send money now here
                    //handleShow();

                }
                catch (e) {
                    handleCloseSuccess();
                // code for error handeling
                            console.log(e)
                    setSendTokenErrorMessage("Invalid Address.");

                    return false;
                }
               
            } 
        } 
        
    }
    const sendAmountNow = async (params) => {
        //console.log(params);
        

        if(params['type'] === 'sol') {
            const amount = params['amount'] * LAMPORTS_PER_SOL; // hardcoded to 1 SOL for now
        
            const solanaWallet = new SolanaWallet(props.provider);

            const pubWal = getCurrentWalletPublicKey();
            const blockhash = (await connection.getRecentBlockhash("finalized")).blockhash;
            const TransactionInstruction = SystemProgram.transfer({
                fromPubkey: params['fromWallet'],
                toPubkey: params['toWallet'],
                lamports: amount,
            });
            const transaction = new Transaction({
            recentBlockhash: blockhash,
            feePayer: pubWal,
            }).add(TransactionInstruction);
            
            //const web3authKey = await getPublisherNew(props.provider); 

            //await sendAndConfirmTransaction(connection, transaction, [web3authKey]);

            const { signature } = await solanaWallet.signAndSendTransaction(transaction);
 
            handleShowSuccess();
            // console.log(signature);
            // toast.success("Transferred Successfully", {
            //     className : "success-toast"
            // });
            doLoadBalance();
            setTimeout(doLoadBalance, 2000);
        }
        // else if(params['type'] === 'usdc'){
        //     await sendUSDCTokens(params);
        //     //getUsdcToken();
        //     setTimeout(getUsdcToken, 2000);
        // }
        handleClose();

}

async function sendUSDCTokens(params) {
 
    // Generate a new keypair to represent the sender
    // const sender = await initializeKeypair(connection)
  
    // Generate a new keypair to represent the sender
    // const receiver = Keypair.generate()
  
    // The MINT address of token to transfer
    const MINT = new PublicKey(getUsdcMintAddress());
  
    // Get the sender's associated token account address
    const senderTokenAccountAddress = await splToken.getAssociatedTokenAddress(
      MINT,
      params['fromWallet']
    )
  
    // Get the receiver's associated token account address
    const receiverTokenAccountAddress = await splToken.getAssociatedTokenAddress(
      MINT,
      params['toWallet']
    )
  
    // Create a new transaction
    const pubWal = getCurrentWalletPublicKey();
    const blockhash = (await connection.getRecentBlockhash("finalized")).blockhash;
    const transaction = new Transaction({
        recentBlockhash: blockhash,
        feePayer: pubWal,
    })
  
    // Create an instruction to create the receiver's token account if it does not exist
    const createAccountInstruction = splToken.createAssociatedTokenAccountInstruction(
      params['fromWallet'],
      receiverTokenAccountAddress,
      params['toWallet'],
      MINT,
      splToken.TOKEN_PROGRAM_ID,
      splToken.ASSOCIATED_TOKEN_PROGRAM_ID
    )
  
    // Check if the receiver's token account exists
    let receiverTokenAccount: splToken.Account;
    try {
      receiverTokenAccount = await splToken.getAccount(
        connection,
        receiverTokenAccountAddress,
        "confirmed",
        splToken.TOKEN_PROGRAM_ID
      )
    } catch (e) {
      // If the account does not exist, add the create account instruction to the transaction
      transaction.add(createAccountInstruction)
    }
    const amount = params['amount'] * 1000000;  //conversion

    // Create an instruction to transfer amount from the sender's token account to the receiver's token account
    const transferInstruction = await splToken.createTransferInstruction(
      senderTokenAccountAddress,
      receiverTokenAccountAddress,
      params['fromWallet'],
      amount
    )

    
    // Add the transfer instruction to the transaction
    transaction.add(transferInstruction)
  
    // Send the transaction signed by the sender
    // const transactionSignature = await connection.sendTransaction(transaction, [
    //     params['fromWallet'],
    // ])

    //const web3authKey = await getPublisherNew(props.provider); 
    //await sendAndConfirmTransaction(connection, transaction, [web3authKey]);
     
    const solanaWallet = new SolanaWallet(props.provider);
    const { signature } = await solanaWallet.signAndSendTransaction(transaction);
    //console.log(signature);

    handleShowSuccess();
  }
  
const doLoadBalance = () => {
    props.loadSolBalance();
}
    const showSendNftConfirm = async () => {
         const userData = getUserSessionData();

         props.loginWeb3Auth();

        if(getUserSessionData() == null || props.provider == null) {
            handleClosePreview();
            props.loginWeb3Auth();
            return;
        }
        
        if(!props.provider){
            handleClosePreview();
            props.loginWeb3Auth();
        }


        if(userData) {
            
            if(userData.wallet) {
                     
                

                    try{
                        const toWallet = (toAddressNftInput.current!.value);

                        await connection.getAccountInfo(new PublicKey(toWallet));
                        // handleShowNftSend();
                        setDestinationWallet(toWallet);
                        setSendNftTitle("CONFIRM SEND");

    
                    }
                    catch (e) {
                    // code for error handeling
                                console.log(e)
                        setSendNftErrorMessage("Invalid Address1.");
                        return false;
                    }
                

            }
        }
    }
    const showSendConfirm = async () => {
        const tokenType = (tokenTypeInput.current!.value);
        let balance = 0;
        const userData = getUserSessionData();

        props.loginWeb3Auth();

        if(getUserSessionData() == null || props.provider == null) {
            handleClosePreview();
            props.loginWeb3Auth();
            return;
        }
        
        if(userData) {
            
            if(userData.wallet) {

                const pubkey =  new PublicKey(userData.wallet);
                if(tokenType === 'usdc') {
                    balance = await getUsdcTokenBalance(connection);
                }else if(tokenType === 'sol') {
                    balance = await getBalance(pubkey, connection);
                }
                const amount = (solAmountInput.current!.value);

                if(balance < parseFloat(amount)) {
                    setSendTokenErrorMessage("Amount is greater the wallet balance.");
                    return false;
                }else {

                    try{
                        const toWallet = (toAddressInput.current!.value);

                        await connection.getAccountInfo(new PublicKey(toWallet));
                        //handleClosePreviewSend();
                        //handleShow();                
                        setSendTokenErrorMessage("");

                        setSelectedTokenType(tokenType.toUpperCase());
                        setDestinationWallet(toWallet);
                        setNumOfToken(amount);
                        setSendTokenTitle('CONFIRM SEND');
                    }
                    catch (e) {
                    // code for error handeling
                                console.log(e)
                        setSendTokenErrorMessage("Invalid Address2.");
                        return false;
                    }
                }

            }
        }
    }

    const handleClose = () => {
       // console.log("handleClose");
        setShow(false);

        handleClosePreviewSend();

    };

    const handleCloseNftSend = () => {
        //console.log("handleCloseNftSend");
        setShowNftSend(false);

        handleClosePreview();

    };

    const backSendNft = () => {
        if(sendNftTitle === 'CONFIRM SEND') {
            setSendNftErrorMessage("");
            setSendNftTitle("SEND NFT");
        }else{
            handleClosePreview();
        }
    }

    const getAssetUriInfo = async(asset) => {
        let resp;

        if(!asset)
            return false;

         try {

            const instance = axios.create({
                timeout: 2000 // 2 seconds
            });
            resp = await instance.post(asset.uri).then((response) => {
                let data = response.data;
                //console.log(data);
                data.address = asset.extraInfo.id;
                data.type = asset.extraInfo.type;

                let collectionName = (response.data.collection ? response.data.collection : 'Collection');
                if(!response.data.collection && asset.collectionName) {
                    collectionName = asset.collectionName;

                    //console.log(asset);
                }

                data.collection = collectionName;

                if(data.image) {
                    //data.address = asset.address;
                    data.collectionAddress = asset.collectionAddress;

                    data.fullName =  collectionName + " # " + response.data.name.split(".")[0];

                    data.timestamp = asset.timestamp;
                    data.date = asset.date;
                    data.hasTransaction = asset.hasTransaction;
                    
                    data.extraInfo = asset.extraInfo;
                    return data;
                }
                return false;
            }).catch((error) => { 
                //toast.error(error.response);
                return false;
            });

            
            if(resp && asset.type === 'cnft') {
                resp.collection =  await getCollectionNameByAssetId(asset.extraInfo.assetId);
            } 
            

        } catch (err: any) {
            return null;
        }
        
        return resp;
    }

    const isNftPin = (address) => {

        let pinnedNfts = JSON.parse(localStorage.getItem("pinnedNfts"));
        // console.log(pinnedNfts);
        // console.log(address);
        for(var i in pinnedNfts) {
            if(pinnedNfts[i] === address) {
                return true;
            }
        }

        return false;
    }

    const loadAllNftImages = async (uris) => {
        //console.log(uris);
        var filteredNft = [];

        setIsProcessing(false);
        let resp; 

        var uris = uris.sort((a, b) => b.timestamp - a.timestamp);

        
        let elem = 0;
        for(var index in uris) {
            
            if(uris[index]) {
                resp = await getAssetUriInfo(uris[index]);
                if(resp ) {


                    if(resp.name != null) {
 
                        filteredNft.push(resp);

                        localStorage.setItem('mintedNfts', JSON.stringify(filteredNft));

                        // if(elem > 9) {
                        //     continue;
                        // }
                        elem++;
                        setRenderedElements(existing => [...existing, <Fragment key={index + (new Date()).getTime()} >
                            
                            <a style={{"textAlign": 'center'}} data-date={resp.date} data-timestamp={new Date(resp.timestamp)} href={'#nftInfo-'+resp.address} onClick={() => showNftDataCheck()} data-address={resp.address} className={"imgSliderDetails-1 nftDetail1 " + (isNftPin(resp.address) ? 'pin-nft' : 'no-pin')}><img className="pin-icon" src="/images/pin.png"/><img alt="" src={resp.image} /> <br />{resp.collection ? resp.collection : 'Collection'} - {resp.name.split(".")[0]}</a>
            
                        </Fragment>]);


                    } 
                }
            }
        }
        
        setAllNfts(filteredNft);

        localStorage.setItem('mintedNfts', JSON.stringify(filteredNft));

        var byRecent = filteredNft.sort((a, b) => b.timestamp - a.timestamp);
        setByRecentNft(byRecent);
        let byCollection = filteredNft.sort((a, b) => a.fullName.localeCompare(b.fullName));
        setByCollectionNft(byCollection);
        loadImageNfts(filteredNft, false, true, byRecent, byCollection);

    }

    const loadImageNfts = (filteredNft, isRefresh, isTimeout, byRecent, byCollection) => {
        setNum(filteredNft.length);



        // if(byRecent.length === byRecentNft.length) {

        //     byRecent = byRecentNft;

        // }


        console.log(byRecent);

        setRenderedElements([]);

        setRenderedElementsNew(byRecent.map( (asset : any, index : number) => {
            return asset && <Fragment key={index + (new Date()).getTime()} >
            
                <a style={{"textAlign": 'center'}} data-date={asset.date} data-timestamp={new Date(asset.timestamp)} href="#NftDetail1" onClick={() => showNftData(asset.address)} data-address={asset.address} className={"imgSliderDetails-1 nftDetail1 " + (isNftPin(asset.address) ? 'pin-nft' : 'no-pin')}>  <img className="overlay pin-icon" src="/images/pin.png"/><img alt="" src={asset.image} /> <br /> {asset.collection ? asset.collection : 'Collection'} - {asset.name.split(".")[0]}</a>

            </Fragment>;
        }));
        setHideInitial(true);

        //setListingJsx(newlistingjsx);


        // if(byCollection.length === byCollectionNft.length) {

        //     byCollection = byCollectionNft;

        // }

        setListingJsxByCollection(byCollection.map( (asset : any, index : number) => {
            return asset && <Fragment key={index + (new Date()).getTime()}>
            
                <a style={{"textAlign": 'center'}} data-date={asset.date} data-timestamp={new Date(asset.timestamp)} href="#NftDetail1" onClick={() => showNftData(asset.address)} data-address={asset.address} className={"imgSliderDetails-1 nftDetail1 " + (isNftPin(asset.address) ? 'pin-nft' : 'no-pin')}><img className="overlay pin-icon" src="/images/pin.png"/><img alt="" src={asset.image} /> <br /> {asset.collection ? asset.collection : 'Collection'} - {asset.name.split(".")[0]}</a>

            </Fragment>;
        }));

        //if(isRefresh)
        
        //setRenderedElementsNew(newlistingjsx);

        setIsProcessing(false);

    }

    const showCollectionLink = async(collectionAddress, collectionName, address, nftName, type) => {

        const params = {
            address: collectionAddress
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/get-info-by-mint-address', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                     
                    setPreviewCollectionName(<a href={"/collection/" + data.address}>{data.name}</a>);
                    if(type === 'nft')
                        setPreviewImageName(<a target="_blank" className="all-caps" rel="noreferrer" href={'https://explorer.solana.com/address/'+address+'?cluster='+ cluster}>{data.name.toUpperCase() + ' - ' + nftName.toUpperCase()}</a>);
                    else
                        setPreviewImageName(<a target="_blank"  rel="noreferrer" href={'https://translator.shyft.to/address/'+address+'/?cluster='+cluster+'&compressed=true'}>{data.name.toUpperCase() + ' - ' + nftName.toUpperCase()}</a>);

                }else{
                    //not in the system, so display address to external url 
                    setPreviewCollectionName(<Fragment>{collectionName}</Fragment>);

                }
            });
            return;
      }

      const showNftDataCheck = () => {

        setTimeout(showNftDataCheckNow, 100)
      }
      const showNftDataCheckNow = () => {
        let address = '';
        if(window.location.hash.indexOf("nftInfo-") === 1) {
            address = window.location.hash.replaceAll("#nftInfo-", "");
        }
        showNftData(address);
      }
    const showNftData = (address) => {
        // console.log(props.provider);
         //if(props.provider) {
            //console.log(allNfts);

            setSendNftTitle("SEND TOKENS");

            //console.log(address);

            let nfts = allNfts === null ? JSON.parse(localStorage.getItem('mintedNfts')) : allNfts;

            if(allNfts) {
                localStorage.removeItem('mintedNfts');
            }
            for(var i in nfts) {
                if(nfts[i].address === address) {
                    //console.log(nfts[i]);
                    setSelectedNftAddress(address);
                    setSelectedNftType(nfts[i].type);

                    //setPreviewCollectionName(nfts[i].collection ? nfts[i].collection : '');
                    let nftName = nfts[i].name.split(".")[0];

                    showCollectionLink(nfts[i].collectionAddress, (nfts[i].collection ? nfts[i].collection : ''), address, nftName, nfts[i].type);

                    setPreviewImageDesc(nfts[i].description);

                    if(nfts[i].type === 'nft') {
                        setPreviewImageName(<a target="_blank"  rel="noreferrer" href={'https://explorer.solana.com/address/'+address+'?cluster='+ cluster}>{nftName}</a>);
                    } else {
                        setPreviewImageName(<a target="_blank"  rel="noreferrer" href={'https://translator.shyft.to/address/'+address+'/?cluster='+cluster+'&compressed=true'}>{nftName}</a>);
                    }

                    setPreviewImageUrl(nfts[i].image);

                    setPropertiesJsx ([]);
                    //console.log(nfts[i]);

                    if(nfts[i].attributes) {
                        //console.log('show att');
                        const attributes = Array.isArray(nfts[i].attributes) ? nfts[i].attributes : JSON.parse(nfts[i].attributes);  
                        //console.log(attributes);

                        // setPropertiesJsx(attributes.map( (asset : any, index : number) => {
                        //     return asset && <Fragment key={index + (new Date()).getTime()}>
                        //          <div className="custom-column">{asset.trait_type} : {asset.value}</div>
                        //     </Fragment>;
                        // }));

                        const chunkedJSX = [];
                        const properties = chunkArray(attributes, 2);

                        for (let i = 0; i < properties.length; i ++) {
                            let elem = properties[i];

                            if(elem.length === 2) {
                                chunkedJSX.push(
                                    <div className='row-elem' key={i}>
                                        {elem[0] &&  <div className="custom-column">{elem[0].trait_type}: <span className="prop-value">{elem[0].value}</span></div>} 
                                        {elem[1] &&  <div className="custom-column">{elem[1].trait_type}: <span className="prop-value">{elem[1].value}</span></div>} 
                                    </div>
                                );
                            }else{
                                chunkedJSX.push(
                                    <div className='row-elem' key={i}>
                                        {elem[0] &&  <div className="custom-column">{elem[0].trait_type}: <span className="prop-value">{elem[0].value}</span></div>} 
                                    </div>
                                );
                            }
                        }
                        setPropertiesJsx (chunkedJSX);
                    } 
                    // setPropertiesJsx()

                    setIsPin(false);
                    
                    if(isNftPin(address)) {
                        setIsPin(true);
                    }

                }
            }
            setSendNftErrorMessage("");
            handleShowPreview();
            //console.log("loginWeb3Auth");
            props.loginWeb3Auth();

        // }else{
        //      props.loginWeb3Auth();
        // }
    }

    const chunkArray = (arr, chunkSize) => {
        const chunkedArray = [];
        
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunkedArray.push(arr.slice(i, i + chunkSize));
        }
        
        return chunkedArray;
        }
      
    const sendNftNow = async () => {
        //console.log("sendNftNow "  + selectedNftAddress);

        if(!props.provider){
            //handleCloseNftSend();
             props.loginWeb3Auth();
        }

        let balance = 0;
        const userData = getUserSessionData();
  
        if(userData) {
            
            if(userData.wallet) {

                const pubkey =  new PublicKey(userData.wallet);
                balance = await getBalance(pubkey, connection);
                
            }
        }

         

        if(balance == 0) {
            handleClosePreview();
            handleShowNeedSol();
            return;
        }
 

            const ownerPublicKey = new PublicKey(userData.wallet);
            const destPublicKey = new PublicKey(toAddressNftInput.current!.value);
          
           // Generate a new keypair to represent the sender
            // const sender = await initializeKeypair(connection)
        
            // Generate a new keypair to represent the sender
            // const receiver = Keypair.generate()
        
            // The MINT address of token to transfer
            const MINT = new PublicKey(selectedNftAddress);

            
 
            if(selectedNftType === 'nft') {
            
                handleShow();
            handleClosePreview();
                // Get the sender's associated token account address
                const senderTokenAccountAddress = await splToken.getAssociatedTokenAddress(
                MINT,
                ownerPublicKey
                )
            
                // Get the receiver's associated token account address
                const receiverTokenAccountAddress = await splToken.getAssociatedTokenAddress(
                MINT,
                destPublicKey
                )
            
                // Create a new transaction
                const pubWal = getCurrentWalletPublicKey();
                const blockhash = (await connection.getRecentBlockhash("finalized")).blockhash;
                const transaction = new Transaction({
                    recentBlockhash: blockhash,
                    feePayer: pubWal,
                })
            
                // Create an instruction to create the receiver's token account if it does not exist
                const createAccountInstruction = splToken.createAssociatedTokenAccountInstruction(
                ownerPublicKey,
                receiverTokenAccountAddress,
                destPublicKey,
                MINT,
                splToken.TOKEN_PROGRAM_ID,
                splToken.ASSOCIATED_TOKEN_PROGRAM_ID
                )
            
                // Check if the receiver's token account exists
                let receiverTokenAccount: splToken.Account;
                try {
                    receiverTokenAccount = await splToken.getAccount(
                        connection,
                        receiverTokenAccountAddress,
                        "confirmed",
                        splToken.TOKEN_PROGRAM_ID
                    )
                } catch (e) {
                // If the account does not exist, add the create account instruction to the transaction
                transaction.add(createAccountInstruction)
                }

                // Create an instruction to transfer amount from the sender's token account to the receiver's token account
                const transferInstruction = await splToken.createTransferInstruction(
                    senderTokenAccountAddress,
                    receiverTokenAccountAddress,
                    ownerPublicKey,
                    1
                )

                
                // Add the transfer instruction to the transaction
                transaction.add(transferInstruction)

                try {
                    const solanaWallet = new SolanaWallet(props.provider);

                    const { signature } = await solanaWallet.signAndSendTransaction(transaction);
                    afterTransferProcessing();
                }
                catch (e) {
                    handleCloseSuccess();
                // code for error handeling
                            console.log(e)
                    setSendNftErrorMessage("Invalid Address.");
                    return false;
                }
            } else {
            
                await transferCompressedNFT();

                
            }
    }
    
    const transferCompressedNFT = async () => {

        try{ 
            
            const asset = await getAsset(selectedNftAddress);
            const assetProof = await getAssetProof(selectedNftAddress);
 
            //Here, we’re looking for the data_hash, creator_hash, owner, delegate, and leaf_id:
            const dataHash = asset.compression.data_hash;
            const creatorHash = asset.compression.creator_hash;
            const leafId = asset.compression.leaf_id;
            const delegate = asset.ownership.delegate;

            //Once we have the necessary information, we need to use the getAssetProof method to retrieve the proof and tree_id (the tree’s address). Here’s an example call:
            const proof = assetProof.proof;
            const treeAddress = new PublicKey(assetProof.tree_id);
            const root = assetProof.root;

            const userData = getUserSessionData();

            const owner = (userData.wallet);
            const newLeafOwner = new PublicKey(toAddressNftInput.current!.value);


            const treeAccount = await ConcurrentMerkleTreeAccount.fromAccountAddress(connection, treeAddress);
        
            const treeAuthority = treeAccount.getAuthority();
            const canopyDepth = treeAccount.getCanopyDepth();
        

            handleShow();
            handleClosePreview();

            const proofPath: AccountMeta[] = proof
            .map((node: string) => ({
                pubkey: new PublicKey(node),
                isSigner: false,
                isWritable: false,
            }))
            .slice(0, proof.length - (!!canopyDepth ? canopyDepth : 0));
        
            const leafOwner = new PublicKey(owner);
            //const leafDelegate = new PublicKey(delegate);
            const leafDelegate = new PublicKey(owner);

            
            const transferInstruction = createTransferInstruction(
            {
                merkleTree: treeAddress,
                treeAuthority,
                leafOwner,
                leafDelegate,
                newLeafOwner,
                logWrapper: SPL_NOOP_PROGRAM_ID,
                compressionProgram: SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
                anchorRemainingAccounts: proofPath,
            },
            {
                root: [...new PublicKey(root.trim()).toBytes()],
                dataHash: [...new PublicKey(dataHash.trim()).toBytes()],
                creatorHash: [...new PublicKey(creatorHash.trim()).toBytes()],
                nonce: leafId,
                index: leafId,
            },
            MPL_BUBBLEGUM_PROGRAM_ID
            );
         

            
                
            const web3authKey = await getPublisherNew(props.provider); 

            const blockhash = (await connection.getRecentBlockhash("finalized")).blockhash;

            const txt = new Transaction().add(transferInstruction);
            txt.feePayer = leafOwner;
            txt.recentBlockhash = blockhash;
            txt.sign(web3authKey);

            let feeEstimate = { priorityFeeEstimate: 0 };
            let priorityLevel = 'High';
            if (priorityLevel !== "NONE") {
                feeEstimate = await getPriorityFeeEstimate(priorityLevel, txt);
                if(feeEstimate.priorityFeeEstimate > 0) {
                    const computePriceIx = ComputeBudgetProgram.setComputeUnitPrice({
                        microLamports: feeEstimate.priorityFeeEstimate,
                    });
                    txt.add(computePriceIx);
                }
            }

            const transactionSignature = await sendAndConfirmTransaction(connection, txt, [web3authKey], {
                commitment: "confirmed",
                skipPreflight: true,
            });
            
        
            //console.log(`Successfully transfered the cNFT with txt sig: ${transactionSignature}`);
             
            afterTransferProcessing();
                

        }
        catch (e) {
            handleCloseSuccess();
            console.log(e)
            setSendNftErrorMessage("Invalid Address.");
            return false;
        }
      };
      
      async function getPriorityFeeEstimate(priorityLevel, transaction) {
        const response = await fetch(process.env.REACT_APP_WEB_AUTH_RPC_TARGET, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jsonrpc: "2.0",
            id: "1",
            method: "getPriorityFeeEstimate",
            params: [
              {
                transaction: base58.encode(transaction.serialize()), // Pass the serialized transaction in Base58
                options: { priorityLevel: priorityLevel },
              },
            ],
          }),
        });
        const data = await response.json();

        //console.log(data);

        if(data.result) {
            console.log(
            "Fee in function for",
            priorityLevel,
            " :",
            data.result.priorityFeeEstimate
            );
            return data.result;
        }
        return { priorityFeeEstimate: 0 };
      }

      const reloadAllNft = () => {
        loadAllNft(currentWallet, hiddenMachine);
      }
    const afterTransferProcessing = () => {
        /*
        var filteredNft = [];
        for(var i in allNfts) {
            if(allNfts[i] && allNfts[i].address !== selectedNftAddress) {
                filteredNft.push(allNfts[i])
            }
        }
        //console.log(filteredNft);
        setAllNfts(filteredNft);

        localStorage.setItem('mintedNfts', JSON.stringify(filteredNft));

        loadImageNfts(filteredNft, true);
        */
        
        setIsProcessing(true);
        setIsRefresh(true);
        setTimeout(reloadAllNft, 2000)

        setShow(false);
        //handleCloseNftSend();
        handleClosePreview();
        handleShowSuccess();
        //loadAllNft();
        
        
    }

    const backSendBalance = () => {
        setSendTokenErrorMessage("");

        setSendTokenTitle("SEND TOKENS");
    }
    const closeSendToken = () => {
        if(sendTokenTitle === 'SEND TOKENS') {
            handleClosePreviewSend();
        }else if(sendTokenTitle === 'CONFIRM SEND') {
            setSendTokenErrorMessage("");
            backSendBalance();
        }
    }
    const showSendBalance = () => {
        //console.log(props.provider);

        if(props.provider) {
            setSendTokenErrorMessage("");

            setSendTokenTitle('SEND TOKENS');
            handleShowPreviewSend();
        }else{
            props.loginWeb3Auth();
        }
    }

    const showByRecent = () => {
        setShowBy('recent');
    }

    const showByCollection = () => {
        setShowBy('collection');
    }

    const showPurchaseSol = () => {
        handleCloseNeedSol();
        openOnRamp();
    }


    const pinNft = async () => {
        let resp = await pinItem (1, selectedNftAddress);
        if(resp) {
            setIsPin(true);
            //console.log(allNfts);
 
            loadImageNfts(allNfts, false, false, byRecentNft, byCollectionNft);
        }
    }
    const unpinNft = async () => {
        let resp = await pinItem (0, selectedNftAddress);
        if(resp) {
            setIsPin(false);
            loadImageNfts(allNfts, false, false, byRecentNft, byCollectionNft);
        }
    }

    const pinItem = async (val, address) => {
        let api = 'pin-nft';
        if(val === 0) {
            api = 'unpin-nft';
        }
     
        const userData = getUserSessionData();
        if(userData) {
            
            if(userData.wallet) {
                
                const params = {
                    token: userData.token,
                    secret: userData.secret,
                    address: address
                }
    
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify(params)
                };
    
                const response = await fetch(backend_api_url + 'api/v1/machine/'+api, requestOptions);
                const json = await response.json();
                if(json.status === 1) {
                    //console.log(json);
                    localStorage.setItem("pinnedNfts", JSON.stringify ( json.pinnedNft) );
                    return true;
                }
            }
        }
        return false; 
    }

    props.setClassInfo('walletPg');

    settingsSlider.infinite = num >= 5 ? true : false;
    

    return (
        <Fragment>

        <div className="contents">

            <h5 className="outer">{username}</h5>

            <div className="tokenDetails">
                
            <div className="walletAddress desktop">
                <p>Wallet address : {<span className="account-wallet"><WalletLink mobile={false} loginWeb3Auth={props.loginWeb3Auth} provider={props.provider} isExternal={true}/></span>} |</p>
                <a href="/wallet-activity" className="viewActivity">view activity</a>
            </div>

            <div className="walletAddress mobile-display">
                <p>Wallet address : {<span className="account-wallet"><WalletLink mobile={true} loginWeb3Auth={props.loginWeb3Auth} provider={props.provider} isExternal={true}/></span>} |</p>
                <a href="/wallet-activity" className="viewActivity">view activity</a>
            </div>

            <div className="br"></div>

            <p><b>SOLs : {props.solBalance.toFixed(4)}<br/>Credits : ${balance.toFixed(0)}</b></p>

            <br/>

            {/* <a href="#SendToken"  className="buttonLink sendTokenButton1" >SEND TOKEN</a> */}
            <button className="buttonLink btn-generic" onClick={showSendBalance}>SEND TOKEN</button>
            <button className="buttonLink btn-generic" onClick={openOnRamp}>PURCHASE SOL</button>
            <button className="buttonLink btn-generic" onClick={openStripe}>PURCHASE CREDITS</button>
            </div>

            <br/>

            <div className="collections recentlyMinted nftsMinted">
            <h5>NFTS MINTED</h5> 
            <button onClick={showByRecent} className="buttonLink btn-generic recentLink">RECENT</button>
            <button onClick={showByCollection} className="buttonLink btn-generic collectionLink">COLLECTION</button>

            <div className="collectionsDiv">
                {
                    isProcessing && <p style={{"textAlign": "left"}}>{isRefresh ? 'Refreshing' : 'Processing'}...</p>
                }
                {
                    !isProcessing && showBy === 'recent' && hideInitial === false && renderedElements
                }
                {
                    !isProcessing && showBy === 'recent' && hideInitial === true && renderedElementsNew
                }
                {
                    !isProcessing && showBy === 'collection' && listingJsxByCollection
                }
            </div>


            </div>

            </div>

            <Modal className="modal-preview" show={showPreviewSend} onHide={closeSendToken}
  size="sm"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title className="custom-modal-title">{sendTokenTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
        <div style={{"color" : 'red', 'textAlign': 'center', "display" : sendTokenErrorMessage !== '' ? 'block' : 'none' }}>{sendTokenErrorMessage}</div>

        <div id='SendToken' style={ {"padding" : '0px', "display" : sendTokenTitle === 'SEND TOKENS' ? 'block' : 'none'}}>
                <form name="frmSendTokens" id="frmSendTokens" method="get" action="#">

                    <table style={ {"border" : 0}} className="sendTokenTable">
                    <tbody>
                    <tr>
                        <td align="right">Token</td>
                        <td align="left">
                        <select name="TokenType" id="TokenType" ref={tokenTypeInput}>
                            <option value="sol">SOLs</option>  
                            {/* <option value="usdc">USDC</option>   */}
                        </select>	
                        </td>	 
                    </tr>

                    <tr>
                        <td align="right">Amount</td>
                        <td align="left">
                        <input type="text" name="TokenAmount" id="TokenAmount" className="textbox" ref={solAmountInput} defaultValue="100" />
                        </td>	 
                    </tr>

                    <tr>
                        <td align="right">To : Wallet</td>
                        <td align="left">
                        <input type="text" name="ToWallet" id="ToWallet" className="textbox" ref={toAddressInput} />
                        </td>	 
                    </tr>
                    </tbody>
                    </table>
                </form>
                <button className="buttonLink btn-generic" onClick={showSendConfirm} >SEND NOW</button>
        </div>
        <div id='SendToken' style={ {"padding" : '10px', "display" : sendTokenTitle === 'CONFIRM SEND' ? 'block' : 'none'}}>
            <div className="form-group" style={{"marginBottom" : "35px", "wordBreak": "break-word"}}>
                Are you sure you want to send {numOfToken} {selectedTokenType} to {destinationWallet}? 
            </div> 
            <button onClick={backSendBalance} className="buttonLink btn-generic">&lt;&lt; BACK</button>

            <button onClick={sendBalanceNow} className="buttonLink btn-generic">SEND</button>
        </div>

        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>

<Modal className="modal-preview" show={showPreview} onHide={backSendNft}
  size="sm"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title className="custom-modal-title">{sendNftTitle == 'CONFIRM SEND' ? sendNftTitle : previewImageName}</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
        <div style={{"color" : 'red', 'textAlign': 'center', "display" : sendNftErrorMessage !== '' ? 'block' : 'none' }}>{sendNftErrorMessage}</div>

                <div id='NftDetail' style={ {"padding" : "10px", "background" : '#ffffff', "margin" : "0px", "display" : sendNftTitle === 'CONFIRM SEND' ? 'none' : 'block'} }>
                    <img alt="" src={previewImageUrl}/>
                    <br/><br/>
                    <p>OWNED BY : <span>{usernameJsx}</span></p>
                    { previewCollectionName && <Fragment><p className="pull-left">COLLECTION : {previewCollectionName}</p></Fragment>}

                    <div className="scrolling-info">
                    <p className="pull-left">DESCRIPTION : <span className="full-text" dangerouslySetInnerHTML={{ __html: previewImageDesc }} /></p>
                     

                    <p className="pull-left">PROPERTIES<br/></p>
                        <div className="custom-row">
                            {
                                propertiesJsx ? propertiesJsx : 'N/A'
                            }
                        </div>
                    </div>
                    {/* <br/> */}
                    <div className="sendNftName">
                        <p>To : Wallet</p>&nbsp;
                        <input type="text" name="ToWallet" id="ToWallet" className="textbox" ref={toAddressNftInput}/>
                        &nbsp;<button onClick={showSendNftConfirm}  className="buttonLink btn-generic">SEND NOW</button>
                    </div>

                    <div className="sendNftName">
                        {
                            isPin && <Fragment> <img className="pin-icon" src="/images/pin.png"/> Pinned - <a href="#pin" onClick={unpinNft}>Unpin</a> </Fragment>
                        }
                        {
                            !isPin && <Fragment> <img className="pin-icon" src="/images/pin.png"/> <a href="#pin" onClick={pinNft}>Pin to User Page</a> </Fragment>
                        }
                        
                        
                    </div>
                </div>

                <div id='SendToken' style={ {"padding" : '10px', "display" : sendNftTitle === 'CONFIRM SEND' ? 'block' : 'none'}}>
                    <div className="form-group" style={{"marginBottom" : "35px", "wordBreak": "break-word"}}>
                            Are you sure you want to send this NFT to this {destinationWallet}? 
                    </div> 
                    <button onClick={backSendNft} className="buttonLink btn-generic">&lt;&lt; BACK</button>

                    <button onClick={sendNftNow} className="buttonLink btn-generic">SEND</button>
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>


    <Modal className="modal-processing" show={showSuccess} onHide={handleCloseSuccess} backdrop="static">
           <Modal.Header closeButton={true}>
                <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
              <div className="success-create">Send Successful</div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 

    <Modal  className="modal-preview"  show={showError} onHide={handleCloseError} backdrop="static">
                <Modal.Header closeButton={true}>
                <Modal.Title>{mintErrorHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
    
                    <div>
                        {mintError}
                    </div>
    
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> 
            
                <Modal className="modal-payment" size="sm" show={showStripe} onHide={handleCloseStripe} backdrop="static">
                <Modal.Header closeButton={true}>
                {/* <Modal.Title>Notification</Modal.Title> */}
                </Modal.Header>
                    <Modal.Body>
                    <div style={{ "textAlign" : "center"}}>
                        {
                            secret && <OnRamp secret={secret} showSuccessPayment={showSuccessPayment}></OnRamp>
                        }
                    </div>
        
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>

                <Modal className="modal-payment" size="sm" show={showNeedSol} onHide={handleCloseNeedSol} backdrop="static">
                <Modal.Header closeButton={true}>
                {/* <Modal.Title>Notification</Modal.Title> */}
                </Modal.Header>
                    <Modal.Body>
                    <div style={{ "textAlign" : "center"}}>
                        You need SOLs to send NFTs to a different wallet. Either send SOLs to this wallet, or <a href="#purchase" onClick={showPurchaseSol}>Purchase SOLs</a>
                    </div>
        
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>



                <Modal className="modal-payment" size="sm" show={showStripeReloadAmount} onHide={handleCloseStripeReloadAmount} backdrop="static">
                    <Modal.Header closeButton={true}>
                    <Modal.Title>Buy Credits
                    </Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                        <div style={{ "textAlign" : "center"}}>
 
                            <div id='SendToken'>
                            <span style={{"fontSize":"12px", "position" : "relative", "top" : "-10px"}}>You currently have {balance.toFixed(2)} credit(s). 1 credit = $1</span>
                                    <form name="frmSendTokens" id="frmSendTokens" method="get" action="#">

                                        <table style={ {"border" : 0}} className="sendTokenTable">
                                        <tbody>
                                        <tr>
                                            <td align="right">Amount</td>
                                            
                                            <td align="left">
                                            <input type="text" className="textbox" required ref={amountInput} defaultValue={100} />
                                            </td>	 
                                        </tr>

                                    
                                        </tbody>
                                        </table>
                                    </form>
                                    <button className="buttonLink btn-generic" onClick={openStripeNow} >PURCHASE NOW</button>
                            </div> 

                        </div>

                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                </Modal>


                <Modal className="modal-payment" size="sm" show={showStripeReload} onHide={handleCloseStripeReload} backdrop="static">
                    <Modal.Header closeButton={true}>
                    {/* <Modal.Title>Notification</Modal.Title> */}
                    </Modal.Header>
                        <Modal.Body>
                        <div style={{ "textAlign" : "center"}}>
                            {
                                secretReload && 
                                <Elements options={options} stripe={stripePromise}>
                                    <div style={{"textAlign": "left"}}>Buy Credits: ${reloadBalance}</div>
                                    <CheckoutForm showSuccessPayment={showSuccessPaymentReload} mint={reloadUserBalanceSave}/>
                                </Elements> 
                            }
                        </div>

                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                </Modal>
                    

                <Modal className="modal-processing" show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton={false}>
                <Modal.Title>Processing...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
    
                <div className="progressBar"><div></div></div>
    
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> 


        </Fragment>

    );
}

export default WalletInfo;