import React, {Fragment, useEffect, useState} from "react";
import { Link, useParams } from "react-router-dom";
import LatestListings from "./LatestListings";
import RecentlyMinted from "./RecentlyMinted";
import CollectionList from "./CollectionList";
import Slider from "react-slick";
import { backend_api_url, settingsHomeBannerSlider, settingsSlider } from "./constants";
import RecentlyJoined from "./RecentlyJoined";
import Modal from 'react-bootstrap/Modal';

const MainContainer = (props: any) => {
 
    const goToAddLink = (e) => {
        e.preventDefault();
        checkLogin();
    }

    const [mintError, setMintError] = useState("");
    const [mintErrorHeader, setMintErrorHeader] = useState("");
    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const checkLogin = () => {
        window.location.href = "/add";
    }

    const paramsPage = useParams();

    const activateAccount = () => {


    if(!paramsPage.id || !paramsPage.email)
        return;
        

    const params = {
        id: paramsPage.id,
        email: paramsPage.email
    } 
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params)
    };
    fetch(backend_api_url + 'api/v1/users/activate', requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.status === 0) {
                //toast.error(data.message);
                setMintErrorHeader("Notification");
                setMintError(data.message);
                handleShowError();
                
            }else if(data.status === 1) {
                //show complete account
                //props.handleShowCompleteAccount();
 
                //toast.success("Account Activated Successfully");
                
                setMintErrorHeader("Notification");
                setMintError("Account Confirmed Successfully");
                handleShowError();

                const userInfo = {
                    token: data.token,
                    secret: data.secret,
                    username: data.username,
                    wallet: data.wallet,
                    isNew: 0
                } 
                localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE, JSON.stringify(userInfo));

            }
        });
}

    useEffect(() => {
        activateAccount();
    }, [paramsPage.id, paramsPage.email]);

    props.setClassInfo("homePg");

    return (
        <Fragment>
            <div id="HomeBanner">
                <div id="HomeBannerDiv" className="slider">
                    <Slider  {...settingsHomeBannerSlider}>
                        <div className="homeBannerDetails">
                            <a href="/collection/8Z2b7eiTr42VcgN1oDBQE71Wz4ubqxdq47hRqFRUGXcj"><img alt="" src="/images/banner/banner0.png" /></a>
                        </div>
                        <div className="homeBannerDetails">
                            <a href="/collection/3fH5YWhS5ejhgwEsnp4hFNoQ47SrrpaeRhwVjDLzWW4H"><img alt="" src="/images/banner/banner1.png" /></a>
                        </div>
                        <div className="homeBannerDetails">
                            <a href="/collection/3wed36zuYT4pnJBRwGuwXFHxZi9NtmVtnpNrhxpES6dJ"><img alt="" src="/images/banner/banner2.jpg" /></a>
                        </div>
                        <div className="homeBannerDetails">
                            <a href="/collection/A9kjjCyz7N5gP7KegkbrfwJ1n2puU97Z9jkcAQaWL5zP"><img alt="" src="/images/banner/banner3.png" /></a>
                        </div>
                      

                    </Slider> 
                </div>
            </div>
            <div className="contents">
                
                {/* <div className="collections popularCollections">
                <h5>POPULAR COLLECTIONS</h5>

                <div className="collectionsDiv">
                    <CollectionList useCarousel={false}  type="popular" search=""></CollectionList>
                </div>	

                </div> */}

                <div className="collections latestCollections">
                    <h5>LATEST COLLECTIONS</h5> 
                    <div id="ImageSlider">
                        <div id="ImageSliderDiv" className="slider slider-minted">
                            <CollectionList useCarousel={true} type="latest" search=""></CollectionList>
                        </div>
                    </div>
 
            
                </div>

                <div className="collections recentlyMinted">
                    <h5>RECENTLY MINTED</h5>

                    <div id="ImageSlider">
                        <div id="ImageSliderDiv" className="slider slider-minted">
                            <RecentlyMinted candyMachineAddress='' username='' useCarousel={true}></RecentlyMinted>
                        </div>
                    </div>

                </div>

                <div className="collections recentlyMinted">
                    <h5>RECENTLY JOINED</h5>

                    <div id="ImageSlider">
                        <div id="ImageSliderDiv" className="slider slider-minted">
                            <RecentlyJoined type='' search=""></RecentlyJoined>
                        </div>
                    </div>

                </div>

            </div>
             
            <Modal  className="modal-processing"  show={showError} onHide={handleCloseError} backdrop="static">
                <Modal.Header closeButton={true}>
                <Modal.Title>{mintErrorHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
    
                    <div style={{"textAlign": "center"}}>
                        {mintError}
                    </div>
    
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> 

        </Fragment>
    );
}

export default MainContainer;