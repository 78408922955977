import React, { Fragment, useEffect, useRef, useState } from "react";
import { backend_api_url, cluster, getAssetsByOwner, getCollectionNameByAssetId, getUserSessionData, settingsSlider } from "./constants";
import WalletLink from "./WalletLink";
import RecentlyMinted from "./RecentlyMinted";
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
import { convertToHTML } from 'draft-convert';
import linkifyHtml from 'linkify-html';
import { EditorState, ContentState } from 'draft-js'
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal'; 
import Editor from '@draft-js-plugins/editor'; 
import Slider from "react-slick";

import { Cropper, CropperRef } from 'react-advanced-cropper'
import 'react-advanced-cropper/dist/style.css';
import axios from "axios";
import { useConnection } from "@solana/wallet-adapter-react";


const MyAccount = (props: any) => {

    const [hasMachines, setHasMachines] = useState(false)

    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)
    const [listingItems, setListingItems] = useState<any[] | null>(null)
    const [username, setUsername] = useState<String>("");

    const [showPreview, setShowPreview] = useState(false);
    const handleClosePreview = () => setShowPreview(false);
    const handleShowPreview = () => setShowPreview(true);

    const [showPreview1, setShowPreview1] = useState(false);
    const handleClosePreview1 = () => setShowPreview1(false);
    const handleShowPreview1 = () => setShowPreview1(true);
   
    const [showPreviewAvatar, setShowPreviewAvatar] = useState(false);
    const handleClosePreviewAvatar = () => setShowPreviewAvatar(false);
    const handleShowPreviewAvatar = () => setShowPreviewAvatar(true);

    const [avatarPreview, setAvatarPreview] = useState<string>("");
    const [previewUrl, setPreviewUrl] = useState<string>("");

    const [isAdmin, setIsAdmin] = useState(false);
    const [isApprove, setIsApprove] = useState(false);

    
    const [isRequestCreate, setIsRequestCreate] = useState(false);

    const [propertiesJsx, setPropertiesJsx] = useState<JSX.Element[] | null>(null)
    
    const [symbol, setSymbol] = useState("");
    const [usernameJsx, setUsernameJsx] = useState<JSX.Element>(null);

    const [previewImageName, setPreviewImageName] = useState<JSX.Element>(null);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const [previewImageDesc, setPreviewImageDesc] = useState("");
    const [previewCollectionName, setPreviewCollectionName] = useState<JSX.Element>(null);
    const [selectedNftAddress, setSelectedNftAddress] = useState("");
    const [selectedNftType, setSelectedNftType] = useState("");
    const [sendNftTitle, setSendNftTitle] = useState("");

     
    const [avatar, setAvatar] = useState<string>("/avatar-bak.png");
	const [about, setAbout] = useState<string>("");
    const [file, setFile] = useState<string>("");
    const hiddenFileInput = useRef<any>(null);
    const linkifyPlugin = createLinkifyPlugin();
    const contentInput = useRef<any>(null);

    const cropperRef = useRef<CropperRef>(null);
    

    const [renderedElements, setRenderedElements] = useState([]);
    const [renderedElementsNft, setRenderedElementsNft] = useState([]);
    const [allNfts, setAllNfts] = useState<any[]>(null)

    const [currentIndex, setCurrentIndex] = useState(0);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [message, setMessage] = useState<string>("");

    const [isProcessing, setIsProcessing] = useState(false);
    const [isPin, setIsPin] = useState(false);

    useEffect(() => {
        const delay = 100; // Adjust the delay (in milliseconds) between rendering elements

        const timer = setTimeout(() => {
        if (listingJsx && currentIndex < listingJsx.length) {
            setRenderedElements((prevElements) => [...prevElements, listingJsx[currentIndex]]);
            setCurrentIndex(currentIndex + 1);
        }
        }, delay);

        return () => clearTimeout(timer);
    }, [currentIndex, listingJsx]);

    const onChangeCropper = (cropper: CropperRef) => {        
        //console.log(cropper.getCoordinates(), cropper.getCanvas());    
        // console.log(cropper.getCanvas()?.toDataURL());
        //setPreviewUrl(cropper.getCanvas()?.toDataURL());
    };

 
    const fetchAllMyGachas = async (): Promise<any> => {
        
        const userData = getUserSessionData();


        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/gachas', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) { 
                    setListingItems(data.machines);
                    
                    if(data.machines.length == 0) {
                        const noneJSX = [];
                        noneJSX.push(<Fragment></Fragment>);
                        setListingJsx(noneJSX);
                        setHasMachines(false);
                    }else{
                        setHasMachines(true);
                        setListingJsx( data.machines.map( (gacha, index) => <Fragment key={generateRandomKey(index)}>
                            {gacha.is_draft === 1 ? <div className='machine-data'><a href={'/edit-collection/'+gacha.id + '/' + gacha.user_id} className="imgSliderDetails-1 collection-info"><img alt="" src={gacha.avatar} /></a><span style={{"textAlign":"center", "display" : "block"}}>{gacha.name}</span><a href={'/edit-collection/'+gacha.id + '/' + gacha.user_id}>Edit Settings</a></div> : <div className='machine-data'><a href={'/collection/'+gacha.candy_machine_address} className="imgSliderDetails-1 collection-info"><img alt="" src={gacha.avatar} /></a><br />
                            <span style={{"textAlign":"center", "display" : "block"}}>{gacha.name}</span>
                            <a href={'/collection/'+gacha.candy_machine_address + '/settings'}>View Settings</a>
                            <span style={{"textAlign":"center", "display" : "block"}}>Created: {gacha.date_created_display}</span></div> }
                        </Fragment>) );
                    }
                }
            });

            
	};

    const saveAccountAvatar = () => {
        const canvas = cropperRef.current?.getCanvas();
        const imageString = canvas?.toDataURL();

        setAvatar(imageString);
        //saveAccountAvatar(imageString);

        const userData = getUserSessionData();


        const params = {
            token: userData.token,
            secret: userData.secret,
            avatar: imageString
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/save-avatar', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    //setUserWallets(wal);
                    //toast.success("Account Save Successfully");
                    //navigate("/account");
                }
            });
            handleClosePreviewAvatar();
    }

    const saveAccountAbout = () => {
        const userData = getUserSessionData();
        const options = { defaultProtocol: 'https' };

        const about = linkifyHtml(convertToHTML(editorState.getCurrentContent()), options);


        const params = {
            token: userData.token,
            secret: userData.secret,
            about: about,
        }
        //console.log(params);

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/save-about', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    //setUserWallets(wal);
                    // toast.success("Save Successfully");
                    //navigate("/account");
                    
                    handleShow();
                    setMessage("Saved Successfully");

                    setAbout(about);
                    handleClosePreview();
                }
            });
    }
    
    const generateRandomKey =(index) => {
        return new Date().getTime() + index;
      }
  
    const handleFileClick = () => {
        
        hiddenFileInput.current.click();
        hiddenFileInput.current.value = "";

    }
    const handleChange = (event: any) => {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            const imageString = event.target?.result as string;
            setFile(imageString); // *** Only change is here
            
            //setAvatar(imageString);
            //saveAccountAvatar(imageString);
            setAvatarPreview(imageString);
            handleShowPreviewAvatar();
        };
    };

    const fetchUserInfo = async (): Promise<any> => {
        
        const userData = getUserSessionData();

        setUsername(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/details', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    //setUserWallets(wal);

                    setIsAdmin(data.is_admin === 1 ? true : false);
                    setIsApprove(data.is_request_collection === 2 ? true : false);
                    if(data.avatar) {
                        setAvatar(data.avatar);
                    }
                    if(data.about) {
                        setAbout(data.about);

                        const processedHTML = DraftPasteProcessor.processHTML(data.about);
                        const contentState = ContentState.createFromBlockArray(processedHTML);
                        setEditorState(EditorState.createWithContent(contentState));
                    }
                    // if(data.email) {
                    //     setEmail(data.email);
                    // }

                   setIsRequestCreate(data.is_request_collection === 1 ? true : false);
                    
                    // setCurrentWallet(data.wallet);
                    // setCountryJsx(data.countries.map( (country) => <option key={country.id} value={country.id}>{country.name}</option> ));
                    // setUserCountryId(data.country_id);
                    // setShowCountry(true);
                }
            });
	}

    const [editorState, setEditorState] = React.useState(
      
          () => {

            const processedHTML = DraftPasteProcessor.processHTML(about);
            const contentState = ContentState.createFromBlockArray(processedHTML);

            return EditorState.moveFocusToEnd(EditorState.createWithContent(contentState));
          }
      );

      const cursorMove = () => {
            
        const currentState = editorState;
        var selectionState = editorState.getSelection()
        setEditorState(EditorState.forceSelection(currentState, selectionState))

      }

      const goToCreateCollection = () => {
        window.location.href = '/create-collection';
      }

      const [showClear, setShowClear] = useState(false);
      const handleCloseClear = () => setShowClear(false);
      const handleShowClear = () => setShowClear(true);

      const requestCreate = () => {
        handleShowClear();
      }

      
    const [currentWallet, setCurrentWallet] = useState("");
    const [hiddenMachine, setHiddenMachine] = useState<any[]>(null)

      const viewNftsOwned = async (username) => {
        const params = {
            username: username
        }

        setUsernameJsx(<a href={"/user/" + username}>{username}</a>);

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/user-wallet', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    
                    //console.log(data.userInfo.primary_wallet);
                    localStorage.setItem("pinnedNfts", JSON.stringify ( data.pinnedNft) );

                    setHiddenMachine (data.xMachines);
                    setCurrentWallet (data.userInfo.primary_wallet) ;
                    loadAllNft(data.userInfo.primary_wallet, data.xMachines, data.pinnedNft);

                }
            });
    }

    const pinNft = async () => {
        let resp = await pinItem (1, selectedNftAddress);
        if(resp) {
            setIsPin(true);
            loadImageNfts(allNfts, false);

        }
    }
    const unpinNft = async () => {
        let resp = await pinItem (0, selectedNftAddress);
        if(resp) {
            setIsPin(false);
            loadImageNfts(allNfts, false);

        }
    }

    const pinItem = async (val, address) => {
        let api = 'pin-nft';
        if(val === 0) {
            api = 'unpin-nft';
        }
     
        const userData = getUserSessionData();
        if(userData) {
            
            if(userData.wallet) {
                
                const params = {
                    token: userData.token,
                    secret: userData.secret,
                    address: address
                }
    
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify(params)
                };
    
                const response = await fetch(backend_api_url + 'api/v1/machine/'+api, requestOptions);
                const json = await response.json();
                if(json.status === 1) {
                    //console.log(json);
                    localStorage.setItem("pinnedNfts", JSON.stringify ( json.pinnedNft) );
                    return true;
                }
            }
        }
        return false; 
    }

    const { connection } = useConnection();


    const loadAllNft = async (wallet, hiddenMachines, pinnedNft) => {
        setIsProcessing(true);
        
      
        const allNFTs = await getAssetsByOwner(wallet);
 
        var data = Object.keys(allNFTs).map((key) => allNFTs[key]);                                                                    
        
        const newArr = await Promise.all(
            data.map(async (asset: any) => {
                let resp = false; 
                if(selectedNftAddress !== '' && selectedNftAddress === asset.mintAddress) {
                    return resp;
                }
                if(asset.uri && asset.uri.indexOf('http') >= 0) {
                    let timestamp = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getTime();
                    let transactions = [];
    
                    //console.log(data[i]);
                    let collectionName = ''; 
    
                    if(asset.type === 'cnft') {
                        collectionName = 'temp-name'; // await getCollectionNameByAssetId(asset.assetId);
                    } 
                    if(hiddenMachines.length === 0 || !hiddenMachines.includes(asset.collectionAddress)) {
                        
                        let address = asset.id ? asset.id : asset.mintAddress;

                        let isAdd = false;
                        for(var x in pinnedNft) {
                            if(pinnedNft[x] === address) {
                                isAdd = true;
                            }
                        }
                        if(!isAdd) {
                            ;return resp;
                        }

                        return ({
                            uri : asset.uri,
                            address: asset.mintAddress,
                            type: asset.type,
                            extraInfo: asset,
                            collectionAddress: asset.collectionAddress,
                            timestamp: timestamp,
                            date: new Date(timestamp),
                            hasTransaction: transactions,
                            collectionName : collectionName
                        });
                    }
                }

                return resp;
            })
        );
        //console.log(newArr);
        loadAllNftImages(newArr);
    }

      const requestCreateCollection = () => {
        const userData = getUserSessionData();

        setUsername(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/request-create', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) { 
                    setIsRequestCreate(true); 
                    handleCloseClear();
                }
            });
      }
      
      const getAssetUriInfo = async(asset) => {
        let resp;

        if(!asset)
            return false;

         try {

            const instance = axios.create({
                timeout: 2000 // 2 seconds
            });
            resp = await instance.post(asset.uri).then((response) => {
                let data = response.data;
                //console.log(data);
                data.address = asset.extraInfo.id;
                data.type = asset.extraInfo.type;

                let collectionName = (response.data.collection ? response.data.collection : 'Collection');
                if(!response.data.collection && asset.collectionName) {
                    collectionName = asset.collectionName;

                    //console.log(asset);
                }

                data.collection = collectionName;

                if(data.image) {
                    //data.address = asset.address;
                    data.collectionAddress = asset.collectionAddress;

                    data.fullName =  collectionName + " # " + response.data.name.split(".")[0];

                    data.timestamp = asset.timestamp;
                    data.date = asset.date;
                    data.hasTransaction = asset.hasTransaction;
                    
                    data.extraInfo = asset.extraInfo;
                    return data;
                }
                return false;
            }).catch((error) => { 
                //toast.error(error.response);
                return false;
            });

            
            if(resp && asset.type === 'cnft') {
                resp.collection =  await getCollectionNameByAssetId(asset.extraInfo.assetId);
            } 
            

        } catch (err: any) {
            return null;
        }
        
        return resp;
    }
      
      const loadAllNftImages = async (uris) => {
        //console.log(uris);
        var filteredNft = [];

        setIsProcessing(false);
        let resp; 
        for(var index in uris) {
            
            if(uris[index]) {
                resp = await getAssetUriInfo(uris[index]);
                if(resp ) {


                    if(resp.name != null) {
 
                        filteredNft.push(resp);

                        localStorage.setItem('mintedNfts', JSON.stringify(filteredNft));

                        setRenderedElementsNft(existing => [...existing, <Fragment key={index + (new Date()).getTime()} >
                    
                            <a style={{"textAlign": 'center'}} data-date={resp.date} data-timestamp={new Date(resp.timestamp)} href={'#nftInfo-'+resp.address} onClick={() => showNftDataCheck()} data-address={resp.address} className="imgSliderDetails-1 nftDetail1"><img alt="" src={resp.image} /> <br />{resp.collection ? resp.collection : 'Collection'} - {resp.name.split(".")[0]}</a>
            
                        </Fragment>]);


                    } 
                }
            }
        }
        
        setAllNfts(filteredNft);

        localStorage.setItem('mintedNfts', JSON.stringify(filteredNft));

      
        loadImageNfts(filteredNft, false);

    }

    const loadImageNfts = (filteredNft, isRefresh) => {
        //setNum(filteredNft.length);


        var byRecent = filteredNft.sort((a, b) => b.timestamp - a.timestamp);

        let newlistingjsx = byRecent.map( (asset : any, index : number) => {
            return asset && <Fragment key={index + (new Date()).getTime()} >
            
                <a style={{"textAlign": 'center'}} data-date={asset.date} data-timestamp={new Date(asset.timestamp)} href="#NftDetail1" onClick={() => showNftData(asset.address)} data-address={asset.address} className={"imgSliderDetails-1 nftDetail1 " + (isNftPin(asset.address) ? 'pin-nft' : 'no-pin')}><img className="pin-icon" src="/images/pin.png"/><img alt="" src={asset.image} /> <br /> {asset.collection ? asset.collection : 'Collection'} - {asset.name.split(".")[0]}</a>

            </Fragment>;
        });

        //if(isRefresh)
        setRenderedElementsNft(newlistingjsx);

        setIsProcessing(false);
    }

    const showNftDataCheck = () => {

        setTimeout(showNftDataCheckNow, 100)
      }
      const showNftDataCheckNow = () => {
        let address = '';
        if(window.location.hash.indexOf("nftInfo-") === 1) {
            address = window.location.hash.replaceAll("#nftInfo-", "");
        }
        showNftData(address);
      }
      const showCollectionLink = async(collectionAddress, collectionName, address, nftName, type) => {

        const params = {
            address: collectionAddress
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/get-info-by-mint-address', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                     
                    setPreviewCollectionName(<a href={"/collection/" + data.address}>{data.name}</a>);
                    if(type === 'nft')
                        setPreviewImageName(<a target="_blank" className="all-caps" rel="noreferrer" href={'https://explorer.solana.com/address/'+address+'?cluster='+ cluster}>{data.name.toUpperCase() + ' - ' + nftName.toUpperCase()}</a>);
                    else
                        setPreviewImageName(<a target="_blank"  rel="noreferrer" href={'https://translator.shyft.to/address/'+address+'/?cluster='+cluster+'&compressed=true'}>{data.name.toUpperCase() + ' - ' + nftName.toUpperCase()}</a>);
                    setSymbol(data.symbol);
                }else{
                    //not in the system, so display address to external url 
                    setPreviewCollectionName(<Fragment>{collectionName}</Fragment>);

                }
            });
            return;
      }
      const chunkArray = (arr, chunkSize) => {
        const chunkedArray = [];
        
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunkedArray.push(arr.slice(i, i + chunkSize));
        }
        
        return chunkedArray;
        }
        
    const backSendNft = () => {
        handleClosePreview1();
        
    }
    const showNftData = (address) => { 

            setSendNftTitle("SEND TOKENS");

            //console.log(address);

            let nfts = allNfts === null ? JSON.parse(localStorage.getItem('mintedNfts')) : allNfts;

            if(allNfts) {
                localStorage.removeItem('mintedNfts');
            }
            setSymbol("-");

            for(var i in nfts) {
                if(nfts[i].address === address) {
                    //console.log(nfts[i]);
                    setSelectedNftAddress(address);
                    setSelectedNftType(nfts[i].type);

                    //setPreviewCollectionName(nfts[i].collection ? nfts[i].collection : '');
                    let nftName = nfts[i].name.split(".")[0];

                    showCollectionLink(nfts[i].collectionAddress, (nfts[i].collection ? nfts[i].collection : ''), address, nftName, nfts[i].type);

                    setPreviewImageDesc(nfts[i].description);

                    if(nfts[i].type === 'nft') {
                        setPreviewImageName(<a target="_blank"  rel="noreferrer" href={'https://explorer.solana.com/address/'+address+'?cluster='+ cluster}>{nftName}</a>);
                    } else {
                        setPreviewImageName(<a target="_blank"  rel="noreferrer" href={'https://translator.shyft.to/address/'+address+'/?cluster='+cluster+'&compressed=true'}>{nftName}</a>);
                    }

                    setPreviewImageUrl(nfts[i].image);

                    setPropertiesJsx ([]);
                    //console.log(nfts[i]);

                    if(nfts[i].attributes) {
                        //console.log('show att');
                        const attributes = Array.isArray(nfts[i].attributes) ? nfts[i].attributes : JSON.parse(nfts[i].attributes);   

                        const chunkedJSX = [];
                        const properties = chunkArray(attributes, 2);

                        for (let i = 0; i < properties.length; i ++) {
                            let elem = properties[i];

                            if(elem.length === 2) {
                                chunkedJSX.push(
                                    <div className='row-elem' key={i}>
                                        {elem[0] &&  <div className="custom-column">{elem[0].trait_type}: <span className="prop-value">{elem[0].value}</span></div>} 
                                        {elem[1] &&  <div className="custom-column">{elem[1].trait_type}: <span className="prop-value">{elem[1].value}</span></div>} 
                                    </div>
                                );
                            }else{
                                chunkedJSX.push(
                                    <div className='row-elem' key={i}>
                                        {elem[0] &&  <div className="custom-column">{elem[0].trait_type}: <span className="prop-value">{elem[0].value}</span></div>} 
                                    </div>
                                );
                            }
                        }
                        setPropertiesJsx (chunkedJSX);
                    } 
                    // setPropertiesJsx()
                    setIsPin(false);
                    
                    if(isNftPin(address)) {
                        setIsPin(true);
                    }
                }
            }
             
            handleShowPreview1(); 
            props.loginWeb3Auth();
 
    }

    const isNftPin = (address) => {

        let pinnedNfts = JSON.parse(localStorage.getItem("pinnedNfts"));
        // console.log(pinnedNfts);
        // console.log(address);
        for(var i in pinnedNfts) {
            if(pinnedNfts[i] === address) {
                return true;
            }
        }

        return false;
    }

	useEffect(() => {
		
        const userData1 = getUserSessionData();
        if(userData1) {
            setUsername(userData1.username);
            viewNftsOwned(userData1.username);

            fetchUserInfo();

            fetchAllMyGachas();

        }else{
            window.location.href = "/";
        }
	  }, []);

      props.setClassInfo("accountPg");

    return (
        <Fragment>
            <div className="contents">

                <h5 className="outer">{username}</h5>

                <div className="rightSideContent aboutCreator username">

                <div className="leftSideDiv">
                    <a href="#" className="createrImg"><img src={avatar} alt=""/></a>
                    <a href="#editImage" onClick={handleFileClick} className="edit">Edit</a>

                    <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{display: 'none'}}
                    />

                </div>

                <p><b>ABOUT : </b><span className="about-us"  dangerouslySetInnerHTML={{ __html: about }} /></p>

                <p className="wallet-data desktop">Wallet Address : {<span className="account-wallet"><WalletLink mobile={false} loginWeb3Auth={props.loginWeb3Auth} provider={props.provider} isExternal={false}/></span>}</p>
                <p className="wallet-data mobile-display">Wallet Address : {<span className="account-wallet"><WalletLink mobile={true} loginWeb3Auth={props.loginWeb3Auth} provider={props.provider} isExternal={false}/></span>}</p>



                <a href="#editAbout" className="edit" onClick={handleShowPreview}>Edit</a>

                

                </div>

                <br/><br/>

                <div className="collections recentlyMinted collectionsCreated">
                <h5>COLLECTIONS CREATED</h5> 
                { (isAdmin || isApprove) && <button  onClick={goToCreateCollection} className="buttonLink btn-generic createLink">CREATE</button>}

                
                <div className="collectionsDiv" style={{"marginTop":"-25px", "padding" : "15px 0px"}}>
                    {
                        !hasMachines && <Fragment>None yet - </Fragment>
                    }
                    {
                        !isAdmin && isRequestCreate && !isApprove  &&  <Fragment>Request Sent</Fragment>
                    }
                    {
                        !isAdmin && !isRequestCreate && !isApprove && <Fragment><a href="#request" onClick={requestCreate}>Request to Create</a></Fragment>
                    }
                </div>
                <div className="collectionsDiv">
                    
                {
                            renderedElements
                        }
                </div>	

                </div>

                <br/><br/><br/><br/>

                <div className="collections recentlyMinted nftsMinted">
                <h5>NFTS MINTED</h5> 
                    {/* {
                        username && <RecentlyMinted type="mine" candyMachineAddress='' username={username} useCarousel={false}></RecentlyMinted>
                    } */}

                    <div className="collectionsDiv">
                        {
                            isProcessing && <p style={{"textAlign": "left"}}>Processing...</p>
                        }
                        {
                            !isProcessing && renderedElementsNft
                        } 
                    </div>

                </div>

                </div>

                <Modal className="modal-preview" show={showPreview} onHide={handleClosePreview}
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title>About Me</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
                <div id='NftDetail' style={ {"padding" : "10px", "background" : '#ffffff', "margin" : "0px"} }>
                     <div onClick={cursorMove} style={{"border": "1px solid #DEDEDE","height": "200px","textAlign": "left"}}>
                <Editor
                    
                    editorState={editorState}
                    onChange={setEditorState}
                    plugins={[linkifyPlugin]}
                     ref={contentInput}
                    />
                    </div> 

                    <div className="sendNftName">
                        <button onClick={saveAccountAbout} className="buttonLink btn-generic">Save</button>
                    </div>
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>


    <Modal className="modal-preview" show={showPreviewAvatar} onHide={handleClosePreviewAvatar}
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
                <div>
                    <div>
                        <div style={{"width" : "300px", "margin" : "0 auto"}}>
 
                        {
                            avatarPreview && 
                            <Cropper  src={avatarPreview} 
                            ref={cropperRef}
                            aspectRatio={{"minimum" : 1, "maximum" : 1}}
                            onChange={onChangeCropper} className={'cropper'}        />
                        }
                             
                            
                        </div>
                        
                        
                        <div className="sendNftName" style={{"textAlign" : "center"}}>
                            <button onClick={saveAccountAvatar} className="buttonLink btn-generic">Save</button>
                        </div>
                    </div>
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>

    <Modal className="modal-processing" show={show} onHide={handleClose} backdrop="static">
    <Modal.Header closeButton={true}>
    {/* <Modal.Title>Notification</Modal.Title> */}
    </Modal.Header>
        <Modal.Body>
        <div style={{ "textAlign" : "center"}}>
            {message}
        </div>

        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
    </Modal> 

    <Modal className="modal-preview" show={showClear} onHide={handleCloseClear} backdrop="static">
    <Modal.Header closeButton={true}>
    <Modal.Title>Confirmation</Modal.Title>
    </Modal.Header>
        <Modal.Body>
        <div style={{ "textAlign" : "center"}}>
            Are you sure you want to request to create a collection?
        </div>

        <div style={{ "textAlign" : "center", "marginTop" : "30px"}}> 
            <button onClick={handleCloseClear} className="buttonLink btn-generic">CANCEL</button>

            <button onClick={requestCreateCollection} className="buttonLink btn-generic">CONFIRM</button>
            </div>
            
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
    </Modal> 
    
    <Modal className="modal-preview" show={showPreview1} onHide={backSendNft}
  size="sm"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title className="custom-modal-title">{sendNftTitle == 'CONFIRM SEND' ? sendNftTitle : previewImageName}</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
            
                <div id='NftDetail' style={ {"padding" : "10px", "background" : '#ffffff', "margin" : "0px", "display" : sendNftTitle === 'CONFIRM SEND' ? 'none' : 'block'} }>
                    <img alt="" src={previewImageUrl}/>
                    <br/><br/>
                    <p>OWNED BY : <span>{usernameJsx}</span></p>
                    { previewCollectionName && <Fragment><p className="pull-left">COLLECTION : {previewCollectionName}</p></Fragment>}
                    <p className="pull-left">Symbol: {symbol}</p>

                    <div className="scrolling-info">
                    <p className="pull-left">DESCRIPTION : <span className="full-text" dangerouslySetInnerHTML={{ __html: previewImageDesc }} /></p>
                     

                    <p className="pull-left">PROPERTIES<br/></p>
                        <div className="custom-row">
                            {
                                propertiesJsx ? propertiesJsx : 'N/A'
                            }
                        </div>
                    </div>
                    
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>
    
        </Fragment>
    );

    
}

export default MyAccount;